import { Space } from 'antd'
import { currencyFormat } from '../../../_helpers'

export const taxColumns =  [
   {
       key: 'period',
       title: 'Period',
       dataIndex: 'period',
       sorter: (a, b) => (a.period || '').localeCompare(b.period || ''),
       ellipsis: { showTitle: false },
   },
   {
       key: 'base_currency',
       title: 'Currency',
       dataIndex: 'base_currency',
       sorter: (a, b) => (a.base_currency || '').localeCompare(b.base_currency || ''),
       ellipsis: { showTitle: false },
   },
   {
       key: 'total_revenues',
       title: 'Total Revenues',
       dataIndex: 'total_revenues',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => currencyFormat(item, record?.base_currency)
   },
   {
       key: 'total_audit_revenues',
       title: 'Audit Revenues',
       dataIndex: 'total_audit_revenues',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'green' }}>{record.total_audit_revenues_pct}%</span>
       </Space>
   },
   {
       key: 'total_inaudit_revenues',
       title: 'Inaudit Revenues',
       dataIndex: 'total_inaudit_revenues',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'green' }}>{record.total_inaudit_revenues_pct}%</span>
       </Space>
   },
   {
       key: 'audit_salaries',
       title: 'Audit Salary',
       dataIndex: 'audit_salaries',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'red' }}>{record.audit_salaries_pct}%</span>
       </Space>
   },
   {
       key: 'inaudit_salaries',
       title: 'Inaudit Salary',
       dataIndex: 'inaudit_salaries',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'red' }}>{record.inaudit_salaries_pct}%</span>
       </Space>
   },
   {
       key: 'total_fixed_costs',
       title: 'Fixed Costs',
       dataIndex: 'total_fixed_costs',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'red' }}>{record.total_fixed_costs_pct}%</span>
       </Space>
   },
   {
       key: 'ebt',
       title: 'EBT',
       dataIndex: 'ebt',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <Space>
           {currencyFormat(item, record?.base_currency)}
           <span style={{ color: 'red' }}>{record.ebt_pct}%</span>
       </Space>
   },
   {
       key: 'business_tax',
       title: 'Taxes',
       dataIndex: 'business_tax',
       sorter: (a, b) => a - b,
       ellipsis: { showTitle: false },
       render: (item, record, index) => currencyFormat(item, record?.base_currency)
   },
]