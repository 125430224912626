import { Tooltip } from 'antd';
import React from 'react';

function TooltipInfo({
    items, render = (value) => { return value }
}) {
    const displayedItem = items.filter(x => ![null, undefined, ''].includes(x))
    if ([0, null, undefined].includes(displayedItem?.length)) return null

    return <Tooltip title={
        <div onClick={(event) => { event.preventDefault(); event.stopPropagation() }}>
            {items.map((x, i) => <div key={i}>{render(x)}</div>)}
        </div>
    }>
        {displayedItem[0] + (displayedItem.length < 2 ? '' : ` (+${displayedItem.length - 1}...)`)}
    </Tooltip>
}

export default TooltipInfo