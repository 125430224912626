import React from 'react';
import { useSelector } from 'react-redux';
import { Reducer } from '../../../_helpers';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { getPolicyColumns } from './columns';

export function PolicyTable(props) {
  const { selectSlice } = Reducer()
  const { query } = useSelector(selectSlice)
  const { display } = props
  const tableProps = {
    columns: [
      ...getPolicyColumns(query.policy_group || 'production'),
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`[${record.name}] ${record.product}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Apply to records'
            ]
          }}
        />
      }
    ],
    searchKeys: [
      'name', 'product', 'products', 'clients', 'editors', 'qcs', 'status', 'type', 'period'
    ],
    arrayKeys: [],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
