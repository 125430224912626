import { Row, Space, Typography } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducer } from '../../../_helpers'
import { selectAccountSlice } from '../../../_reducers/account'
import BaseTable from '../_base/BaseTable'
import { orderColumns } from './columns'
import ProductCard from './ProductCard'

export function WaitingTable(props) {
  const dispatch = useDispatch()
  const { selectSlice, takeQcs, returnQcs } = Reducer()
  const { extras } = useSelector(selectSlice)
  const { info } = useSelector(selectAccountSlice)
  const data = extras.waiting_list?.map(x => ({...x, key: x.id})) || []
  const tableProps = {
    columns: [
      ...orderColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 200,
        render: (item, row, index) => {
          var returnable = row.product_list.findIndex(
            x => x.qc === info?.id && ['new', 'edited'].includes(x.last_action)
          ) > -1
          return <Space split={<span style={{ color: 'grey' }}>|</span>}>
            <Typography.Link id='qc-take-btn'
              onClick={() => {
                dispatch(takeQcs({ type: 'job', data: [row.id]}))
              }}
            >take</Typography.Link>
            <Typography.Link id='qc-return-btn' disabled={!returnable}
              onClick={() => {
                dispatch(returnQcs({ type: 'job', data: [row.id] }))
              }}
            >return</Typography.Link>
          </Space>
        }
      }
    ],
    searchKeys: [
      'date', 'client_name', 'job_ref', 'notes', 'products', 'amounts', 'prices',
      'editors', 'edit_rates', 'qcs', 'qc_rates', 'pr_editors', 'pr_qcs',
      'status', 'product_status', 'request_status', 'billing_status'],
    arrayKeys: ['products', 'amounts', 'prices', 'editors', 'edit_rates', 'qcs', 'qc_rates', 'pr_editors', 'pr_qcs'],
    expandable: {
      expandedRowRender: (row, index) => <Row gutter={[8, 8]}>
        {
          <ProductCard {...props} record={row} />
        }
      </Row>,
      rowExpandable: row => true,
    }
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
      data={data}
      selectable={false}
    />
  )
}
