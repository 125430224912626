import { createSlice } from "@reduxjs/toolkit";
import dayjs from 'dayjs';
import { createFolders, deleteObjects, getJobTree, renameObject, selectFolder, selectJob, storage, update } from "../_actions/storage";
import { copyObject, currentSearch, failureReducer, loadingReducer, objectEqual, setLeafValue, setPath, Status } from "../_helpers";

/**
 * @type {StorageState}
 */
const initialState = {
    query: currentSearch(),
    status: Status.IDLE,
    loading: {},
    forms: {},
    upload: {
        class: 'upload-progress-hide',
        batchKey: '',
        batchSize: 0,
        queue: [],
        objects: [],
        status: Status.IDLE
    },
    selections: [],
    visible: { drawer: false },
    viewType: localStorage.getItem('storage/viewType') || 'grid'
};

const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        /**
         * 
         * @returns 
         */
        clear() {
            return { ...initialState, query: {}, columns: {} }
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload }
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setTemp(state, action) {
            const { record, temp } = state;
            const { path, value } = action.payload;
            var rec = copyObject(temp || record);
            setPath(rec, path, copyObject(value));
            if (objectEqual(rec, record)) state.temp = null;
            else state.temp = rec;
        },
        /**
         * Remove record from temporary list
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        delTemp(state, action) {
            state.temp = null
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        resetUpload(state, action) {
            state.upload = {
                ...state.upload,
                class: action.payload?.class || 'upload-progress-hide',
                startTime: dayjs().unix(),
                batchKey: action.payload?.batchKey,
                batchSize: action.payload?.batchSize || 0,
                queue: action.queue,
                objects: [],
                finished: 0
            };
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        addUpload(state, action) {
            const { key, name, size } = action.payload;
            state.upload = {
                ...state.upload,
                objects: [
                    ...state.upload.objects,
                    {
                        key, name, size,
                        progress: 0, loaded: 0, total: 0,
                        tooltip: 'Uploading', status: 'active'
                    }
                ],
                status: Status.LOADING
            }
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setProgress(state, action) {
            const { key, data } = action.payload;
            var { objects, batchSize } = state.upload
            const idx = objects?.findIndex(x => x.key === key);
            if (idx > -1) {
                const progress = data.loaded ?
                    Math.round((100 * data.loaded) / data.total) :
                    objects[idx].progress;
                objects[idx] = { ...objects[idx], progress, ...data };
            }
            const finished = objects.filter(x => x.status === 'success').length
            if (finished > 0 && finished === batchSize) state.upload.status = Status.SUCCESS
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<StorageState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        handleStream(state, action) {
            var { jobTree } = state;
            const { data } = action.payload;
            if (jobTree == null) return { ...state }
            setLeafValue(
                jobTree.tree_data, data.id,
                "editing_status", data.status.toLowerCase()
            )
        }
    },
    extraReducers(builder) {
        builder
            .addCase(storage.fulfilled, (state, action) => {
                const { job_tree, file_data, order_info, options } = action.payload.data;
                state.jobTree = job_tree;
                state.fileData = file_data;
                state.record = order_info;
                state.temp = null;
                state.options = options;
                state.expandedKeys = order_info ? [
                    order_info?.date.slice(0, 4), order_info?.date.slice(0, 7), order_info?.date
                ] : [];
                state.loading = {};
                state.status = Status.SUCCESS;
            })
            .addCase(getJobTree.fulfilled, (state, action) => {
                state.loading = { ...state.loading, jobTree: false };
                state.jobTree = action.payload.data.job_tree;
            })
            .addCase(selectJob.fulfilled, (state, action) => {
                state.fileData = action.payload.data.file_data;
                state.record = action.payload.data.order_info;
                state.status = Status.SUCCESS;
            })
            .addCase(selectFolder.fulfilled, (state, action) => {
                const { file_data, order_info } = action.payload.data;
                state.fileData = file_data;
                state.record = order_info || state.record;
                state.temp = order_info == null ? state.temp : null;
                state.status = Status.SUCCESS;
            })
            .addCase(update.fulfilled, (state, action) => {
                const { file_data, order_info } = action.payload.data;
                state.fileData = file_data || state.fileData;
                state.record = order_info;
                state.temp = null;
                state.status = Status.SUCCESS;
            })
            .addCase(createFolders.fulfilled, (state, action) => {
                const { file_data } = action.payload.data;
                state.fileData = file_data || state.fileData;
                state.status = Status.SUCCESS;
            })
            .addCase(deleteObjects.fulfilled, (state, action) => {
                const { deleted_keys } = action.payload.data;
                const { fileData } = state;
                state.loading = { ...state.loading, folder: false };
                state.fileData = {
                    ...fileData,
                    children: fileData.children.filter(x => !deleted_keys.includes(x.key))
                };
                state.status = Status.SUCCESS;
            })
            .addCase(renameObject.fulfilled, (state, action) => {
                const { renamed } = action.payload.data;
                var { fileData } = state;
                renamed.forEach(x => {
                    const objIdx = fileData.children.findIndex(xx => xx.key === x.source);
                    if (objIdx > -1) {
                        fileData.children[objIdx] = { ...fileData.children[objIdx], ...x.data };
                    };
                })
                state.status = Status.SUCCESS
            })

        builder
            .addMatcher(
                (action) => action.type.startsWith('storage') && action.type.endsWith('/rejected'),
                failureReducer
            )
            .addMatcher(
                (action) => action.type.startsWith('storage') && action.type.endsWith('/pending'),
                loadingReducer
            )
    }
})

export const { clear, setState, setTemp, delTemp, resetUpload, addUpload, setProgress, handleStream } = storageSlice.actions;

/**
 * Get storage slice
 *
 * @param {Object} state
 * @returns {StorageState}
 */
export const selectStorageSlice = (state) => state.storage;

export default storageSlice.reducer;

