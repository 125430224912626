import {
  FileImageOutlined
} from '@ant-design/icons';
import { Image } from 'antd';
import React from 'react';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { productColumns } from './columns';

export function ProductTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      {
        key: 'image_urls',
        title: '',
        dataIndex: 'image_src',
        ellipsis: { showTitle: false },
        width: 100,
        render: (items, record, index) => {
            const images = items.map(x => x.url).filter(x => x)
            return images.length > 0 ? <Image
              width={80} src={images[images.length - 1]}
            /> : <FileImageOutlined style={{fontSize: 40, color: '#8c8c8c'}}/>
        }
      },
      ...productColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`[${record.name}] ${record.product}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Activate', 'Deactivate', 'Delete'
            ]
          }}
        />
      }
    ],
    searchKeys: ['title'],
    arrayKeys: [],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
