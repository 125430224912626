import { login, register } from '../_actions/auth';
import { logout } from '../_reducers/auth';

export const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case register.fulfilled.type:
    case login.fulfilled.type:
      window.localStorage.setItem('jwt', action.payload.access_token);
      break;
    case logout.type:
      window.localStorage.removeItem('jwt');
      break;
    default:
      break;
  }

  return next(action);
}