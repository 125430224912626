import { currencyFormat } from '../../../_helpers';
import ArrayInfo from '../../display/ArrayInfo';
import TagInfo from '../../display/TagInfo';

export const getPolicyColumns = (group) => {
    switch (group) {
        case 'sales': return salesColumns;
        case 'production': return prodColumns;
        default: return []
    }
}

export const salesColumns = [
    {
        key: 'period',
        title: 'Period',
        dataIndex: 'period',
        sorter: (a, b) => (a.period || '').localeCompare(b.period || ''),
    },
    {
        key: 'agents_name',
        title: 'Sales Agents',
        dataIndex: 'agents_name',
        sorter: (a, b) => (a.agents_name || '').localeCompare(b.agents_name || ''),
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
    },
    {
        key: 'lower_kpi',
        title: 'Lower KPI',
        dataIndex: 'lower_kpi',
        sorter: (a, b) => (a.lower_kpi || 0) - (b.lower_kpi || 0),
        render: (item) => item === -1 ? null : new Intl.NumberFormat('en-US', { notation: 'standard' }).format(item)
    },
    {
        key: 'upper_kpi',
        title: 'Upper KPI',
        dataIndex: 'upper_kpi',
        sorter: (a, b) => (b.upper_kpi || 0) - (b.upper_kpi || 0),
        render: (item) => item === -1 ? null : new Intl.NumberFormat('en-US', { notation: 'standard' }).format(item)
    },
    {
        key: 'rate_type',
        title: 'Rate Type',
        dataIndex: 'rate_type',
        sorter: (a, b) => (a.rate_type || '').localeCompare(b.rate_type || ''),
    },
    {
        key: 'payment_rate',
        title: 'Payment Rate',
        dataIndex: 'payment_rate',
        sorter: (a, b) => (b.payment_rate || 0) - (b.payment_rate || 0),
        render: (item, record, index) => record.rate_type === 'absolute' && record.currency ?
            currencyFormat(item, record.currency, 'standard') : new Intl.NumberFormat('en-US', { notation: 'standard' }).format(item * 100) + '%'
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
]

export const prodColumns = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
    },
    {
        key: 'price_ratio',
        title: 'Price Ratio',
        dataIndex: 'price_ratio',
        sorter: (a, b) => (b.price_ratio || 0) - (b.price_ratio || 0),
    },
    {
        key: 'edit_ratio',
        title: 'Edit Ratio',
        dataIndex: 'edit_ratio',
        sorter: (a, b) => (b.edit_ratio || 0) - (b.edit_ratio || 0),
    },
    {
        key: 'qc_ratio',
        title: 'QC Ratio',
        dataIndex: 'qc_ratio',
        sorter: (a, b) => (b.edit_ratio || 0) - (b.edit_ratio || 0),
    },
    {
        key: 'salary_ratio',
        title: 'Salary Ratio',
        dataIndex: 'salary_ratio',
        sorter: (a, b) => (b.salary_ratio || 0) - (b.salary_ratio || 0),
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
    },
]