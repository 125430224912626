import { currencyFormat } from "../../../_helpers";
import TagInfo from '../../display/TagInfo';

export const productColumns = [
    {
        key: 'title',
        title: 'Title',
        dataIndex: 'title',
        width: '25%',
        sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
        ellipsis: { showTitle: false },
    },
    {
        key: 'description',
        title: 'Description',
        dataIndex: 'description',
        width: '40%',
        className: 'long-text-cell',
    },
    {
        key: 'test_edit_rate',
        title: '[T] Edit Rate',
        dataIndex: 'test_edit_rate',
        width: '10%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.test_edit_rate?.amount || 0) - (b.test_edit_rate?.amount || 0),
        render: (item, record) => item ? currencyFormat(item?.amount, item?.currency, 'standard') : null
    },
    {
        key: 'test_qc_rate',
        title: '[T] QC Rate',
        dataIndex: 'test_qc_rate',
        width: '10%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.test_qc_rate?.amount || 0) - (b.test_qc_rate?.amount || 0),
        render: (item, record) => item ? currencyFormat(item?.amount, item?.currency, 'standard') : null
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
        render: (items) => <TagInfo {...{ items }} />
    }
]