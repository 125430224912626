import requests from "./_base";

export const Agent = {
    /**
     * Common API
     * @param {String} document 
     * @param {String} id 
     * @returns {Object}
     */
    search: (document, id) => requests.get(`/api/agent/records/${document}`, { id }),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} tags 
     * @returns 
     */
    searchTags: (document, tags) => requests.post(`/api/agent/records/tags/${document}`, tags),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    create: (document, query, data) => requests.post(`/api/agent/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    update: (document, query, data) => requests.patch(`/api/agent/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} data 
     * @returns 
     */
    updateMany: (document, data) => requests.patch(`/api/agent/records/bulk/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {String} status 
     * @param {Array<String>} data  record id(s)
     * @returns 
     */
    updateStatus: (document, status, data) => requests.patch(`/api/agent/records/status/${document}`, { status }, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} data record id(s)/api/agent
     * @returns 
     */
    export: (document, data) => requests.post(`/api/agent/records/export/${document}`, {}, data),
    /**
     * Contract API
     * @param {String} id 
     * @param {('clients'|'editors'|'qcs')} field
     * @param {Object} data 
     * @returns 
     */
    updateContractParty: (id, field, data) => requests.patch(`/api/agent/contract/party/${id}`, { field }, data),
    /**
     * Client API
     * @param {Object} data 
     * @returns 
     */
    getClientToScan: () => requests.get(`/api/agent/client/scan`),
    /**
     * 
     * @param {String} key s3 key
     * @returns 
     */
    importClients: (key) => requests.post(`/api/agent/client/import`, { key }),
    /**
     * Client API
     * @param {Object} data 
     * @returns 
     */
    verifyUserShortName: (name) => requests.post(`/api/agent/client/user/verify/${name}`),
    /**
     * Client API
     * @param {Object} data 
     * @returns 
     */
    createClientUser: (data) => requests.post(`/api/agent/client/user`, {}, data),
    /**
     * Campaign API
     * @param {Object} data 
     * @returns 
     */
    scheduleCampaign: (id, send_at) => requests.patch(`/api/agent/campaign/schedule/${id}`, { send_at }),
    /**
     * Campaign API
     * @param {Object} data 
     * @returns 
     */
    pauseCampaignSchedule: (id) => requests.patch(`/api/agent/campaign/pause/${id}`),
    /**
     * Campaign API
     * @param {Object} data 
     * @returns 
     */
    cancelCampaign: (id) => requests.del(`/api/agent/campaign/${id}`),
    /**
     * 
     * @param {('api_integration'|'role_parameters'|'logs'|'user'|'product'|'contract'|
     * 'policy'|'order'|'timebook'|'billing'|'transaction')} document 
     * @param {Object} data 
     * @returns 
     */
    dashboard: (document, data) => requests.post(`/api/agent/dashboard/${document}`, {}, data),
}
