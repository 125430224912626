import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { config } from './app/config';
import LoadingScreen from './components/routes/LoadingScreen';
import PrivateRoute from './components/routes/PrivateRoute';
import Home from './pages/home/Home';
import { Auth } from './_api';
import { Status } from './_helpers';
import { selectAuthSlice, setState } from './_reducers/auth';
import { connect, selectSocketSlice } from './_reducers/socket';

const AuthLogin = lazy(() => import('./pages/login/AuthLogin'))
const GoogleLogin = lazy(() => import('./pages/login/GoogleLogin'))
const TestSubmit = lazy(() => import('./pages/login/TestSubmit'))

const TokenVerification = lazy(() => import('./pages/login/TokenVerification'))
const Welcome = lazy(() => import('./pages/welcome/Welcome'))
const UserAccount = lazy(() => import('./pages/account/UserAccount'));
const AdminDashboard = lazy(() => import('./pages/dashboard/admin/AdminDashboard'));
const PmDashboard = lazy(() => import('./pages/dashboard/pm/PmDashboard'));
const CsDashboard = lazy(() => import('./pages/dashboard/cs/CsDashboard'));
const QcDashboard = lazy(() => import('./pages/dashboard/qc/QcDashboard'));
const EditorDashboard = lazy(() => import('./pages/dashboard/editor/EditorDashboard'));
const ClientDashboard = lazy(() => import('./pages/dashboard/client/ClientDashboard'));
const AgentDashboard = lazy(() => import('./pages/dashboard/agent/AgentDashboard'));

const AdminStorage = lazy(() => import('./pages/storage/admin/AdminStorage'));
const ClientStorage = lazy(() => import('./pages/storage/client/ClientStorage'));
const CsStorage = lazy(() => import('./pages/storage/cs/CsStorage'));

const AdminReport = lazy(() => import('./pages/reports/admin/AdminReport'));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selectAuthSlice);
  const socket = useSelector(selectSocketSlice);
  var [validated, setValidated] = useState(false);
  useEffect(() => {
    if (validated === false && auth.token != null) {
      Auth.validateToken().then(res => { })
        .catch(err => {
          localStorage.removeItem('jwt');
          dispatch(setState({ token: null, user: null }));
          navigate('/login');
        })
    }
    setValidated(true);
  }, [dispatch, navigate, auth, validated]);
  useEffect(() => {
    if (auth.token != null && socket.status === Status.IDLE && socket.attemps < 3) {
      const timer = setInterval(() => {
        dispatch(connect({ host: config.wsRoot + `?token=${auth.token}` }));
      }, (socket.attemps + 1) * 1000);
      return () => clearInterval(timer);
    }
  }, [dispatch, auth, socket]);

  return (
    <>
      <Suspense
        fallback={
          <LoadingScreen />
        }
      >
        <Routes>
          <Route path='/' element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route exact path='/login' element={<AuthLogin />} />
          <Route exact path='/email-verify' element={<TokenVerification />} />
          <Route exact path='/email-reset' element={<TokenVerification />} />
          <Route exact path='/password-reset' element={<TokenVerification />} />
          <Route exact path='/google-login' element={<GoogleLogin />} />
          <Route exact path='/sample-test' element={<TestSubmit />} />

          <Route exact path='/welcome'
            element={<PrivateRoute><Welcome /></PrivateRoute>}
          />
          <Route exact path='/user/account'
            element={<PrivateRoute><UserAccount /></PrivateRoute>}
          />
          {/* Dashboard */}
          <Route exact path='/admin/dashboard'
            element={<PrivateRoute><AdminDashboard /></PrivateRoute>}
          />
          <Route exact path='/pm/dashboard'
            element={<PrivateRoute><PmDashboard /></PrivateRoute>}
          />
          <Route exact path='/cs/dashboard'
            element={<PrivateRoute><CsDashboard /></PrivateRoute>}
          />
          <Route exact path='/qc/dashboard'
            element={<PrivateRoute><QcDashboard /></PrivateRoute>}
          />
          <Route exact path='/editor/dashboard'
            element={<PrivateRoute><EditorDashboard /></PrivateRoute>}
          />
          <Route exact path='/client/dashboard'
            element={<PrivateRoute><ClientDashboard /></PrivateRoute>}
          />
          <Route exact path='/agent/dashboard'
            element={<PrivateRoute><AgentDashboard /></PrivateRoute>}
          />
          {/* Storage */}
          <Route exact path='/admin/storage'
            element={<PrivateRoute><AdminStorage /></PrivateRoute>}
          />
          <Route exact path='/client/storage'
            element={<PrivateRoute><ClientStorage /></PrivateRoute>}
          />
          <Route exact path='/cs/storage'
            element={<PrivateRoute><CsStorage /></PrivateRoute>}
          />
          {/* Reports */}
          <Route exact path='/admin/reports'
            element={<PrivateRoute><AdminReport /></PrivateRoute>}
          />
        </Routes>
        
      </Suspense>

    </>

  );
}

export default App;
