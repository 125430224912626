import { Col, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Reducer } from '../../../_helpers';
import { periodTags } from '../../../_styles/tag.styles';
import TagInfo from '../../display/TagInfo';
import BaseTable from '../_base/BaseTable';

export function TimebookViewTable(props) {
  const { selectSlice } = Reducer();
  const { options } = useSelector(selectSlice);
  const colOptions = options?.columns || []
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      width: '100px',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      fixed: 'left',
      width: '70px',
      sorter: (a, b) => (a.role || '').localeCompare(b.role || ''),
      render: (items) => <TagInfo {...{ items }} />
    },
    ...colOptions.map(col => {
      var date = dayjs(col, 'YYYY-MM-DD').format('ddd, MMM D YYYY')
      return {
        title: <span className={col === dayjs(new Date()).format('YYYY-MM-DD') ? 'highlighted-cell' : ''}>{date}</span>,
        dataIndex: col,
        width: '100px',
        render: (items, record) => {
          return <Row key={record.key}>{
            items?.filter(it => it.status !== "UNASSIGNED").map(it => {
              var display = periodTags.find(x => x.label === it && x.status === "ATTENDED")
              return <Col key={it}><Tag color={display.color}>{display.label}</Tag></Col>
            })
          }</Row>
        }
      }
    })
  ]
  const tableProps = {
    columns: columns,
    searchKeys: ['name', 'role', ...colOptions],
    arrayKeys: [...colOptions],
    bordered: true
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
