import { currencyFormat } from '../../../_helpers';
import TagInfo from '../../display/TagInfo';

export const billColumns = [
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.date || '').localeCompare(b.date || ''),
    },
    {
        key: 'user',
        title: 'User',
        dataIndex: 'user',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.user || '').localeCompare(b.user || ''),
    },
    {
        key: 'amount',
        title: 'Amount',
        dataIndex: 'amount',
        sorter: (a, b) => a.amount > b.amount,
        ellipsis: { showTitle: false },
        render: (item, record, index) => currencyFormat(record?.amount, record?.currency)
    },
    {
        key: 'currency',
        title: 'Currency',
        dataIndex: 'currency',
        sorter: (a, b) => (a.currency || '').localeCompare(b.currency || ''),
        ellipsis: { showTitle: false }

    },
    {
        key: 'type',
        title: 'Type',
        dataIndex: 'type',
        sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
    {
        key: 'status_tags',
        title: 'Tags',
        dataIndex: 'status_tags',
        width: '30%',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
]