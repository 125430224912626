import { Image } from 'antd';
import React from 'react';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { campaignColumns } from './columns';

export function CampaignTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      {
        key: 'first_image_url',
        title: '',
        dataIndex: 'first_image_url',
        ellipsis: { showTitle: false },
        width: 100,
        render: (item, record, index) => <Image
          width={80} src={item} preview={{ src: record.snapshot_image_url }}
        />
      },
      ...campaignColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`${record.date} ${record.user}`}
          record={record}
          display={{
            actions: display?.actions ||  [
              'View and edit', 'Set schedule', 'Pause schedule', 'Cancel campaign'
            ]
          }}
        />
      }
    ],
    searchKeys: ['title', 'agent_name', 'delivered', 'status'],
    arrayKeys: ['status'],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
