import { createSelector, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getResetToken, login, register } from "../_actions/auth";
import { failureReducer, loadingReducer, localToken, Status } from "../_helpers";

/**
 * @type {AuthState}
 */
const initialState = {
    status: Status.IDLE,
    currentForm: 'sign-in',
    token: localToken(),
    user: localToken() ? JSON.parse(window.atob(localToken()?.split('.')[1], 'base64')) : null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload }
        },
        /**
         * Update token
         * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
         */
        setToken(state, action) {
            state.token = action.payload;
        },
        /**
         * Log out the user
         * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
         */
        logout: (state) => {
            state.token = null;
            state.user = null;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(register.fulfilled, (state, action) => {
                message.info(<>
                    The verification email has been sent to <b>{action.payload?.email}</b>.
                    <br />
                    If you don't find it in your mailbox, please check your spam folder.
                </>, 20);
                state.currentForm = 'sign-in';
                state.status = Status.SUCCESS;
            })
            .addCase(login.fulfilled, (state, action) => {
                const { access_token } = action.payload
                state.token = access_token;
                state.user = access_token ?
                    JSON.parse(window.atob(access_token?.split('.')[1], 'base64')) :
                    null;
                state.status = Status.SUCCESS;
            })
            .addCase(getResetToken.fulfilled, (state, action) => {
                message.success(action.payload.message);
                state.status = Status.SUCCESS;
            })


        builder
            .addMatcher(
                (action) => action.type.startsWith('auth') && action.type.endsWith('/rejected'),
                failureReducer
            )
            .addMatcher(
                (action) => action.type.startsWith('auth') && action.type.endsWith('/pending'),
                loadingReducer
            )
    }
})

export const { setState, setToken, logout } = authSlice.actions;

/**
 * Get auth slice
 *
 * @param {Object} state
 * @returns {AuthState}
 */
export const selectAuthSlice = (state) => state.auth;

/**
 * Get is authenticated
 *
 * @param {Object} state
 * @returns {boolean}
 */
export const selectIsAuthenticated = createSelector(
    (state) => selectAuthSlice(state).token,
    (state) => selectAuthSlice(state).user,
    (token, user) => Boolean(token && user)
);

export default authSlice.reducer;

