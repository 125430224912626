import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';


function TitleHelp({ label, description }) {
    return (
        description == null ? label :
            <Space  size='small'>
                {label}
                <Tooltip title={description}><QuestionCircleOutlined style={{ cursor: 'pointer' }} /></Tooltip>
            </Space>
    )
}

export default TitleHelp