import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    accountColumns, billColumns, campaignColumns, clientColumns, contractColumns,
    getPolicyColumns, logColumns, orderColumns, productColumns, roleParamColumns, transactionColumns,
    userColumns
} from '../components/tables';
import '../_actions/dashboard';
import { create, dashboard, getPage, getStatistics, importClients, importTimebook, search, takeQcs, update, _delete } from '../_actions/dashboard';
import { copyObject, currentPaths, currentSearch, download, failureReducer, loadingReducer, objectEqual, parseRecord, setPath, Status } from '../_helpers';

/**
 * Set table columns
 * @param {*} doc 
 * @returns 
 */
export const getColumns = (doc) => {
    var options = []
    switch (doc) {
        case 'billing_account': options = accountColumns; break;
        case 'billing': options = billColumns; break;
        case 'campaign': options = campaignColumns; break;
        case 'client': options = clientColumns; break;
        case 'contract': options = contractColumns; break;
        case 'logs': options = logColumns; break;
        case 'order': options = orderColumns; break;
        case 'policy': options = getPolicyColumns(currentPaths().policy_group); break;
        case 'product': options = productColumns; break;
        case 'role_parameter': options = roleParamColumns; break;
        case 'transaction': options = transactionColumns; break;
        case 'user': options = userColumns; break;
        default: break;
    }
    options = options.length > 0 ? [
        { label: '(Selection)', value: 'selection' },
        ...options.map(x => ({ label: x.title, value: x.key }))
    ] : []
    var selected
    if (doc === 'timebook') {
        selected = {
            users: JSON.parse(localStorage.getItem('columns/timebook/users')),
            tags: JSON.parse(localStorage.getItem('columns/timebook/tags'))
        }
        localStorage.setItem('columns/timebook/users', JSON.stringify(selected.users))
        localStorage.setItem('columns/timebook/tags', JSON.stringify(selected.tags))
    } else {
        selected = JSON.parse(localStorage.getItem(`columns/${doc}`)) || options.map(x => x.value)
        localStorage.setItem(`columns/${doc}`, JSON.stringify(selected))
    }
    return {
        selected: selected,
        options
    }
}
/**
 * @type {DashboardState}
 */
const initialState = {
    query: currentSearch(),
    columns: getColumns(currentSearch().document),
    status: Status.IDLE,
    visible: {},
    loading: {},
    table: { page: 1, pageSize: localStorage.getItem(`${currentSearch().document}/pageSize`) || 50 },
    extras: {},
    forms: {},
    records: [],
    temp: [],
    statistics: {}
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        /**
         * 
         * @returns 
         */
        clear() {
            return { ...initialState, query: {}, columns: {} }
        },
        /**
         * Set state
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload };
        },
        /**
         * Add an item to options
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        addOption(state, action) {
            state.options[action.payload.key].unshift(action.payload.value);
        },
        /**
         * Select a table row
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        selectRow(state, action) {
            const { key } = action.payload
            if (key != null && state.records.find(x => x.key === key) == null) return
            state.selectedKey = key;
            state.visible.drawer = key == null ? false : true;
            state.extras = { ...state.extras, record: { ...state.records.find(x => x.key === key) } }
        },
        /**
         * Temporarily set the record
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setTemp(state, action) {
            var recIdx, tempIdx, rec;
            var { records, temp } = state;
            var { key, path, value } = action.payload;
            recIdx = records.findIndex(x => x.key === key);
            tempIdx = temp.findIndex(x => x.key === key);
            rec = copyObject(tempIdx > -1 ? temp[tempIdx] : (recIdx > -1 ? records[recIdx] : null));
            setPath(rec, path, copyObject(value));
            if (objectEqual(rec, records[recIdx])) {
                if (tempIdx > -1) temp.splice(tempIdx, 1);
            } else {
                if (tempIdx > -1) temp[tempIdx] = rec
                else temp.push(rec);
            };
        },
        /**
         * Remove record from temporary list
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        delTemp(state, action) {
            const tempIdx = state.temp.findIndex(x => x.key === action.payload.key)
            if (tempIdx > -1) state.temp.splice(tempIdx, 1);
        },
        /**
         * Temporarily set timebook record
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         * @returns 
         */
        setTimebook(state, action) {
            var { key, date, period, user, value } = action.payload;
            var record = state.records?.find(x => x.date === date);
            if (record == null) return { ...state };
            var schedule = record[user].find(x => x.period === period);
            if (schedule == null) return { ...state };
            schedule[key] = value;
            const tempIdx = state.temp.find(d => d.date === date);
            if (tempIdx > -1) {
                if (objectEqual(record, state.temp[tempIdx])) state.temp.splice(tempIdx, 1);
            } else {
                state.temp.push(copyObject(record));
            };
        },
        /**
         * Handle stream data
         * @param {import('@reduxjs/toolkit').Draft<DashboardState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        handleStream(state, action) {
            const { data, qcAssigned } = action.payload;
            const recIdx = state.records.findIndex(d => d.id === data.id);
            if (qcAssigned === false) {
                if (recIdx > -1) state.records.splice(recIdx, 1)
            } else {
                if (recIdx > -1) state.records[recIdx] = parseRecord(data);
                else if (data.status === 'active') state.records.unshift(parseRecord(data));
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(dashboard.fulfilled, (state, action) => {
                var { data, count, page, options, extras } = action.payload;
                if (state.query.document !== 'home') {
                    state.records = [
                        // ...state.records,
                        ...data.map((x, i) => parseRecord(x, i))
                        // .filter(d => state.records.findIndex(sd => sd.id === d.id) < 0),
                    ];
                    state.table.page = page || state.table.page
                    state.table.count = count || data.length
                } else state.statistics = data;
                const isValidID = state.records.findIndex(x => x.id === state.query.id) > -1
                state.selectedKey = isValidID ? state.query.id : state.selectedKey;
                state.options = options || {};
                state.extras = { ...state.extras, ...extras };
                state.visible.drawer = state.selectedKey != null;
                state.loading = {};
                state.status = Status.SUCCESS;
            })
            .addCase(getPage.fulfilled, (state, action) => {
                var { data, extras } = action.payload;
                state.records = data.map((x, i) => parseRecord(x, i));
                state.temp = state.temp.filter(x => data.find(xx => xx.id === x.id) != null);
                state.extras = { ...state.extras, ...extras };
                state.selectedKey = data.find(x => x.id === state.selectedKey)?.id;
                state.visible.drawer = state.selectedKey != null;
                state.loading = { ...state.loading, page: false };
            })
            .addCase(getStatistics.fulfilled, (state, action) => {
                var { chart, data } = action.payload;
                state.loading[chart] = false;
                state.statistics = { ...state.statistics, ...data };
                state.status = Status.SUCCESS;
            })
            .addCase(search.fulfilled, (state, action) => {
                var { data } = action.payload;
                if (data.length === 0) {
                    message.error('No data found!');
                } else {
                    state.records = [
                        ...data.map((x, i) => parseRecord(x, i)),
                        ...state.records.filter(x => data.find(xx => xx.id === x.id) == null)
                    ];
                    state.selectedKey = data[0].id;
                    state.visible.drawer = true;
                }
                state.status = Status.SUCCESS;
            })
            .addCase(create.fulfilled, (state, action) => {
                var { data, options } = action.payload;
                if (!Array.isArray(data)) data = [data];
                state.records = [
                    ...data.map((x, i) => parseRecord(x, i)),
                    ...state.records.filter(x => data.find(xx => xx.id === x.id) == null)
                ];
                // More update if document is timebook
                if (state.query.document === 'timebook') {
                    state.options.columns = options?.columns || [];
                }
                state.status = Status.SUCCESS;
            })
            .addCase(update.fulfilled, (state, action) => {
                var { data, options } = action.payload;
                if (!Array.isArray(data)) data = [data];
                for (let d of data) {
                    // reset record in data array
                    const recIdx = state.records.findIndex(x => x.id === d.id);
                    if (recIdx > -1) state.records[recIdx] = parseRecord(d, recIdx);
                    // remove record in temp
                    const tempIdx = state.temp.findIndex(x => x.id === d.id);
                    if (tempIdx > -1) state.temp.splice(tempIdx, 1);
                }
                state.extras = { ...state.extras, record: { ...state.records.find(x => x.key === state.selectedKey) } }
                state.options = { ...state.options, ...options };
                state.status = Status.SUCCESS;
            })
            .addCase(importTimebook.fulfilled, (state, action) => {
                var { data, columns } = action.payload;
                data = data.map((x, i) => parseRecord(x, i))
                state.temp = copyObject(data)
                data = data.filter(x => state.records.findIndex(xx => xx.date === x.date) < 0)
                state.records = [...data, ...state.records];
                state.columns.selected.users = [...new Set([...state.columns.selected.users, ...columns.map(col => col.user)])];
                state.options = { ...state.options, columns: [...state.options.columns, ...columns] }
                state.status = Status.SUCCESS;
            })
            .addCase(importClients.fulfilled, (state, action) => {
                var { data, errors } = action.payload;
                if (errors?.url != null) download(errors.url, 'client_errors.xlsx')
                for (let d of data) {
                    // reset record in data array
                    const recIdx = state.records.findIndex(x => x.id === d.id);
                    if (recIdx > -1) state.records[recIdx] = parseRecord(d, recIdx);
                    else state.records.unshift(parseRecord(d))
                    // remove record in temp
                    const tempIdx = state.temp.findIndex(x => x.id === d.id);
                    if (tempIdx > -1) state.temp.splice(tempIdx, 1);
                }
                state.status = Status.SUCCESS;
            })
            .addCase(_delete.fulfilled, (state, action) => {
                var delIdx = action.payload.data.deleted_id;
                if (!Array.isArray(delIdx)) delIdx = [delIdx];
                for (let i of delIdx) {
                    const recIdx = state.records.findIndex(x => x.id === i);
                    if (recIdx > -1) state.records.splice(recIdx, 1);

                    const tempIdx = state.temp.findIndex(x => x.id === i);
                    if (tempIdx > -1) state.temp.splice(tempIdx, 1);

                    if (state.selectedKey === i) state.selectedKey = null;
                }
                state.table.selections = state.table.selections?.filter(key => !delIdx.includes(key));
                state.status = Status.SUCCESS;
            })
            .addCase(takeQcs.fulfilled, (state, action) => {
                for (let x of action.payload.data) {
                    // reset order history in data array
                    const recIdx = state.records.findIndex(xx => xx.id === x.id)
                    if (x.is_assigned) {
                        if (recIdx > -1) state.records[recIdx] = parseRecord(x)
                        else state.records.unshift(parseRecord(x))
                    } else {
                        if (recIdx > -1) state.records.splice(recIdx, 1)
                    }
                    const waitIdx = state.extras.waiting_list?.findIndex(xx => xx.id === x.id)
                    if (x.is_waiting) {
                        if (waitIdx > -1) state.extras.waiting_list[waitIdx] = parseRecord(x)
                        else state.extras.waiting_list.unshift(parseRecord(x))
                    } else {
                        if (waitIdx > -1) state.extras.waiting_list.splice(waitIdx, 1)
                    }
                }
                state.table.selections = []
                state.status = Status.SUCCESS
            })


        builder
            .addMatcher(
                (action) => action.type.startsWith('dashboard') && action.type.endsWith('/rejected'),
                failureReducer
            )
            .addMatcher(
                (action) => action.type.startsWith('dashboard') && action.type.endsWith('/pending'),
                loadingReducer
            )
    }
})

export const {
    clear, setState, addOption, selectRow, setTemp, delTemp,
    setTimebook, unsetTimebook, handleStream
} = dashboardSlice.actions;

/**
 * Get dashboard slice
 *
 * @param {Object} state
 * @returns {DashboardState}
 */
export const selectDashboardSlice = (state) => state.dashboard;

export default dashboardSlice.reducer;