import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { config } from '../../app/config';
import { selectAuthSlice } from '../../_reducers/auth';


function PrivateRoute({ children, ...rest }) {
    const { user } = useSelector(selectAuthSlice)
    const isLogged = user?.exp > (new Date().getTime() / 1000)
    const isUnassign = user?.roles?.length === 1 && window.location?.pathname !== '/welcome'
    const path = window.location?.pathname?.split('/')[1]
    const validRole = config.rolePaths.find(x => x.loginPath.split('/')[1] === path)?.role || 'user'
    const isValidRole = user?.roles?.includes(validRole)

    return (
        <>
            {
                isLogged && isValidRole
                    ? (
                        isUnassign && rest.location?.pathname !== '/welcome'
                            ? <Navigate to={{ pathname: '/welcome', state: { from: '/login' } }} />
                            : children
                    )
                    : <Navigate to={{ pathname: '/login', state: { from: rest.location } }} />
            }
        </>

    )
}

export default PrivateRoute