import { PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Col, DatePicker, Divider, Input, Select, Space, Switch, Typography } from 'antd';
import dayjs from "dayjs";
import React from 'react';
import { useDispatch } from 'react-redux';
import { parseInputType, Reducer, resolvePath } from '../../_helpers';


function RowInfo(props) {
    const dispatch = useDispatch();
    /**
     * 
     * @param {Object} props 
     * @returns 
     */
    const getInput = ({
        record, path, type, options, inputType,
        allowClear, disabled, width, height,
        title, checkedText, uncheckedText,
        children, rowKey = null, setTemp, addOption
    }) => {
        const updateFn = setTemp || Reducer().setTemp
        const addOptFn = addOption || Reducer().addOption 
        var value = resolvePath(record, path)
        var selectOptions = options instanceof Function ? options() : options || []
        const id = path.replace('.', '-')
        switch (type) {
            case 'Input':
                return <span id={id} key={`input_${rowKey}` || 1}>
                    <Input style={{ width: width || '100%' }}
                        type={inputType || 'text'}
                        size='small' disabled={disabled}
                        value={value}
                        onChange={(e) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: parseInputType(inputType, e.target.value)
                            }))
                        }}
                    />
                </span>
            case 'Date':
                return <div id={id} key={`date_${rowKey}` || 2} >
                    <DatePicker popupClassName={id}
                        style={{ width: width || '100%' }}
                        allowClear={false} size='small' disabled={disabled}
                        value={value ? dayjs(value) : null}
                        onChange={(e) => {
                            dispatch(updateFn({
                                key: record.id, path, value: e.format('YYYY-MM-DD')
                            }))
                        }}
                    />
                </div>
            case 'DateTime':
                return <div id={id} key={`datetime_${rowKey}` || 3} >
                    <DatePicker popupClassName={id}
                        showTime style={{ width: width || '100%' }}
                        allowClear={false} size='small' disabled={disabled}
                        value={value ? dayjs.unix(value) : null}
                        onChange={(e) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: e.valueOf() / 1000
                            }))
                        }}
                    />
                </div>
            case 'TextArea':
                return <span id={id} key={`textarea_${rowKey}` || 4}>
                    <Input.TextArea style={{ width: width || '100%', height: height || '50px' }}
                        size='small' disabled={disabled} allowClear={allowClear || false}
                        value={value} onChange={(e) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: e.target.value
                            }))
                        }}
                    />
                </span>
            case 'Select':
                return <span id={id} key={`select_${rowKey}` || 5}>
                    <Select popupClassName={id}
                        style={{ width: width || '100%' }}
                        allowClear={allowClear || false} size='small' disabled={disabled}
                        value={value} options={selectOptions}
                        onChange={(value) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: value
                            }))
                        }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </span>
            case 'SelectExtend':
                return <span id={id} key={`selectextend_${rowKey}` || 6}>
                    <Select popupClassName={id}
                        style={{ width: width || '100%' }}
                        allowClear size='small' disabled={disabled}
                        value={value} options={selectOptions}
                        onChange={(value) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: value
                            }))
                        }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        dropdownRender={menu => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                    <Input placeholder="Please enter item" value={value}
                                        onChange={(values) => {
                                            dispatch(updateFn({
                                                key: record.id, path,
                                                value: values
                                            }))
                                        }}
                                    />
                                    <Typography.Link onClick={addOptFn} style={{ whiteSpace: 'nowrap' }}>
                                        <PlusOutlined /> Add item
                                    </Typography.Link>
                                </Space>
                            </>
                        )}
                    />
                </span>
            case 'AutoComplete':
                return <span id={id} key={`select_${rowKey}` || 5}>
                    <AutoComplete popupClassName={id}
                        style={{ width: width || '100%' }}
                        allowClear={allowClear} size='small' disabled={disabled}
                        value={value} options={selectOptions}
                        onChange={(value) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: value
                            }))
                        }}
                        filterOption={(inputValue, option) =>
                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </span>
            case 'TagSelect':
                return <span id={id} key={`tagselect_${rowKey}` || 7}>
                    <Select style={{ width: width || '100%' }}
                        mode='tags' size='small' disabled={disabled}
                        maxTagCount={3}
                        placeholder={`Please select ${title?.toLowerCase()}`}
                        value={value} options={selectOptions}
                        onChange={(value) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: value
                            }))
                        }}
                    />
                </span>
            case 'InputRow':
                return <Space style={{ width: width || '100%' }}>
                    {
                        children?.map((child, idx) => getInput({
                            record, ...child, path: `${path}.${child.path || child.key}`, disabled, rowKey: idx
                        }))
                    }
                </Space>
            case 'Switch':
                return <span id={id} key={`switch_${rowKey}` || 8}>
                    <Switch
                        checkedChildren={checkedText} unCheckedChildren={uncheckedText}
                        disabled={disabled} checked={value}
                        onChange={(checked, event) => {
                            dispatch(updateFn({
                                key: record.id, path,
                                value: checked
                            }))
                        }}
                    />
                </span>
            default:
                return <></>

        }
    }

    return [
        <Col span={8} key={1}>
            <Space>
                {props.title}
                {props.extraInfo}
            </Space>
        </Col>,
        <Col span={16} key={2}>
            <div >
                {getInput(props)}
            </div>
        </Col>
    ]
}

export default RowInfo