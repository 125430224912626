import { apiType } from "../_helpers";
import requests from "./_base";

export const Admin = {
    /**
     * Common API
     * @param {String} document 
     * @param {String} id 
     * @returns {Object}
     */
    search: (document, id) => requests.get(`/api/admin/records/${document}`, { id }),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} tags 
     * @returns 
     */
    searchTags: (document, data) => requests.post(`/api/admin/records/tags/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    create: (document, query, data) => requests.post(`/api/admin/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    update: (document, query, data) => requests.patch(`/api/admin/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} data 
     * @returns 
     */
    updateMany: (document, data) => requests.patch(`/api/admin/records/bulk/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {String} status 
     * @param {Array<String>} data  record id(s)
     * @returns 
     */
    updateStatus: (document, status, data) => requests.patch(`/api/admin/records/status/${document}`, { status }, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} data record id(s)
     * @returns 
     */
    export: (document, data) => requests.post(`/api/admin/records/export/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} data record id(s)
     * @returns 
     */
    delete: (document, data) => requests.del(`/api/admin/records/${document}`, {}, data),
    /**
     * User API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    updateUserRoles: (id, data) => requests.patch(`/api/admin/user/roles/${id}`, {}, data),
    /**
     * User API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    updateUserContracts: (id, data) => requests.patch(`/api/admin/user/contracts/${id}`, {}, data),
    /**
     * Contract API
     * @param {String} id 
     * @param {('clients'|'editors'|'qcs')} field
     * @param {Object} data 
     * @returns 
     */
    updateContractParty: (id, field, data) => requests.patch(`/api/admin/contract/party/${id}`, { field }, data),
    /**
     * Contract API
     * @param {String} id 
     * @returns 
     */
    makeContractDefault: (id) => requests.post(`/api/admin/contract/default/${id}`),
    /**
     * Policy API
     * @param {*} id 
     * @param {Object} [query] 
     * @param {String} [query.policy_group]
     * @param {('clients'|'editors'|'qcs'|'employees'|'agents')} [query.field]
     * @param {Object} data 
     * @returns 
     */
    updatePolicyObject: (id, { policy_group, field }, data) => requests.patch(`/api/admin/contract/default/${id}`, { policy_group, field }, data),
    /**
     * Policy API
     * @param {Object} data 
     * @returns 
     */
    applyPolicies: (data) => requests.post(`/api/admin/policy/apply`, {}, data),
    /**
     * Order API
     * @param {Boolean} unset 
     * @param {Object} query 
     * @param {Array<String>} data record ids
     * @returns 
     */
    updateOrderHistory: (unset, query, data) => requests.patch(`/api/admin/order/history/${unset ? 'unset' : 'set'}`, query, data),
    /**
     * Order API
     * @param {('create'|'add'|'remove')} action 
     * @param {Object} data 
     * @returns 
     */
    updateOrderBilling: (action, data) => requests.patch(`/api/admin/order/billing/${action}`, {}, data),
    /**
     * Order API
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    addRequest: (id, query, data) => requests.post(`/api/admin/order/request/${id}`, query, data),
    /**
     * 
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    updateRequest: (id, query, data) => requests.patch(`/api/admin/order/request/${id}`, query, data),
    /**
     * Order API
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    addRequestComment: (id, query, data) => requests.post(`/api/admin/order/request-comment/${id}`, query, data),
    /**
     * Order API
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    modifyRequestComment: (id, query, data) => requests.patch(`/api/admin/order/request-comment/${id}`, query, data),
    /**
     * Order API
     * @param {('pending'|'working'|'delivered'|'canceled')} status 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    setRequestStatus: (status, query, data) => requests.patch(`/api/admin/order/request/status/${status}`, query, data),
    /**
     * Timebook API
     * @param {String} key s3 file key
     * @returns 
     */
    importTimebook: (key) => requests.get(`/api/admin/timebook/import`, { key }),
    /**
     * Timebook API
     * @param {('add'|'remove')} action 
     * @param {Object} query 
     * @returns 
     */
    updateTimebookBillRefs: (action, query, data) => requests.patch(`/api/admin/timebook/billing/${action}`, query, data),
    /**
     * Client API
     * @param {Object} data 
     * @returns 
     */
    createClientUser: (data) => requests.post(`/api/admin/client/user`, {}, data),
    /**
     * Billing API
     * @param {Object} data 
     * @returns 
     */
    recalculateBills: (data) => requests.post(`/api/admin/billing/recalculate`, {}, data),
    /**
     * Billing API
     * @param {('single'|'separate')} type 
     * @param {Object} data 
     * @returns 
     */
    makeBillsPayment: (type, data) => requests.post(`/api/admin/billing/payment/${type}`, {}, data),
    /**
     * Tax API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    payTaxSalary: (id, data) => requests.post(`/api/admin/tax/salary-payment/${id}`, {}, data),
    /**
     * Tax API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    recalculateTax: (id, data) => requests.post(`/api/admin/tax/recalculate/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Array<String>} data paths of the folders
     * @returns 
     */
    createFolders: (id, data) => requests.post(`/api/admin/objects/folders/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {'Input'|'Output'} folder_type 
     * @param {Array<String>} data paths
     * @returns 
     */
    listObjects: (id, { folder_type }, data) => requests.post(`/api/admin/objects/list/${id}`, { folder_type }, data),
    /**
     * S3 Objects API
     * @param {String} id  order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    deleteObjects: (id, data) => requests.del(`/api/admin/objects/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Object} [query] 
     * @param {String} [query.source] s3 key
     * @param {String} [query.name]
     * @returns 
     */
    renameObject: (id, { source, name }) => requests.patch(`/api/admin/objects/${id}`, { source, name }),
    /**
     * 
     * @param {String} id order ID
     * @param {Array<String>} data 
     * @returns 
     */
    download: (id, data) => requests.streamFile(`/api/admin/objects/download/${id}`, {}, data),
    /**
     * 
     * @param {('api_integration'|'role_parameters'|'logs'|'user'|'product'|'contract'|
     * 'policy'|'order'|'timebook'|'billing'|'transaction')} document 
     * @param {Object} data 
     * @returns 
     */
    dashboard: (document, data) => requests.post(`/api/admin/dashboard/${document}`, {}, data),
    /**
     * Report API
     * @param {String} document 
     * @param {Object} data 
     * @returns 
     */
    reports: (document, data) => requests.post(`/api/admin/reports/${document}`, {}, data),
    /**
     * Report API
     * @param {String} name 
     * @param {Object} data 
     * @returns 
     */
    statistics: (name, data) => requests.post(`/api/admin/statistics/${apiType()}-${name}`, {}, data),
    /**
     * 
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    storage: (id, data) => requests.post(`/api/admin/storage`, { id }, data),
    /**
     * 
     * @param {String} key search key
     * @returns 
     */
    jobTree: (key) => requests.get(`/api/admin/storage/job-tree`, { key }),
    /**
     * 
     * @param {String} id order ID
     * @returns 
     */
    job: (id) => requests.get(`/api/admin/storage/job-info`, { id }),
    /**
     * 
     * @param {String} id order ID
     * @param {String} path s3 path
     * @returns 
     */
    folder: (id, path) => requests.get(`/api/admin/storage/job-folder`, { id, path }),
    /**
     * 
     * @param {String} id order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    postUpload: (id, data) => requests.post(`/api/admin/storage/upload-finish/${id}`, {}, data)
}
