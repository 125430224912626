/**
 * 
 * @param {Object} a 
 * @param {Object} b 
 * @returns 
 */
export function objectEqual(a, b) {
    if (typeof(a) !== typeof(b)) return false
    if ((a == null && b!= null) || (a != null && b == null)) return false
    else if (a==null && b == null) return true
    else if (['string', 'number', 'boolean'].includes(typeof a)) {
        if (a !== b) return false
    } else if (Array.isArray(a)) {
        if (!Array.isArray(b)) return false
        if (!arrayEqual(a, b)) return false
    } else {
        if (Object.keys(a).length !== Object.keys(b).length) return false
        for (let f of Object.keys(a)) {
            if (!objectEqual(a[f], b[f])) return false
        }
    }

    return true
}
/**
 * 
 * @param {Object} a 
 * @param {Object} b 
 * @returns 
 */
export function arrayEqual(a, b) {
    if (a?.length !== b?.length) return false
    for (let i = 0; i < a.length; i++) {
        if (['string', 'number'].includes(typeof a[i])) {
            if (a[i] !== b[i]) return false
        } else {
            if (!objectEqual(a[i], b[i])) return false
        }
    }
    return true
}
/**
 * 
 * @param {Object} obj 
 * @returns 
 */
export function isNullObject(obj) {
    for (let f of Object.keys(obj)) {
        if (obj[f] == null) return true
    }
    return false
}
/**
 * 
 * @param {Object} object 
 * @returns 
 */
export function copyObject(object) {
    if (object == null) return null
    var result = JSON.parse(JSON.stringify(object))
    return result
}
/**
 * 
 * @param {Object} item 
 * @param {Object} field parentKey, key
 * @param {Object} value 
 */
// export function assignValue(item, field, value) {
//     if (field.parentKey == null) {
//         item[field.key] = copyObject(value)
//     } else {
//         if (item[field.parentKey] == null) {
//             item[field.parentKey] = {}
//         }
//         item[field.parentKey][field.key] = copyObject(value)
//     }
// }
/**
 * 
 * @param {Object} object 
 * @param {String} path format a.b[i].c
 * @param {Object|String|Number} defaultValue 
 * @returns 
 */
export function resolvePath(object, path, defaultValue) {
    return path
        .split(/[.[\]'"]/)
        .filter(p => p)
        .reduce((o, p) => o ? o[p] : defaultValue, object)
}
/**
 * 
 * @param {Object} object 
 * @param {String} path 
 * @param {Object|String|Number} value 
 * @returns 
 */
export function setPath(object, path, value) {
    const paths = path.split(/[.[\]'"]/).filter(p => p)
    return paths.reduce((o, p, i) => {
        o[p] = paths.length === ++i ? value : (o[p] == null ? {} : o[p])
        return o[p]
    }, object)
}