import React from 'react'
import { billColumns } from './columns'
import RecordActions from '../_shared/RecordActions'
import BaseTable from '../_base/BaseTable'

export function BillTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      ...billColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`${record.date} ${record.user}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Recalculate',
              'Return to draft', 'Sent to user', 'Move to bad debt', 'Mark as loss', 'Remove'
            ]
          }}
        />
      }
    ],
    searchKeys: ['date', 'user', 'currency', 'type', 'status_tags'],
    arrayKeys: ['status_tags'],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
