import { CreditCardOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Select, Space, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { copyObject, Reducer, Status } from '../../../_helpers';

function TaxRecalculateForm(props) {
    const dispatch = useDispatch()
    const {
        selectSlice, setState, recalculateTax
    } = Reducer();
    const { status, forms, options } = useSelector(selectSlice);
    const { settings } = forms || {}
    const { visible, taxId, newSetting, period, fullAudit, partialAudit, fullInaudit, insuranceBase } = settings || {
        fullAudit: [], partialAudit: [], fullInaudit: [], insuranceBase: [{ employee: null }]
    }
    const BASE_WIDTH = props.BASE_WIDTH || 500
    /**
     * 
     * @param {Object} values 
     */
    const setFormState = (values) => {
        dispatch(setState(
            {
                forms: {
                    ...forms,
                    settings: { ...settings, ...values }
                }
            }
        ));
    }
    /**
     * 
     * @param {Object} values form data
     */
    const onFinish = async (values) => {
        const { individual_insurance_base } = values
        const data = newSetting === true ? {
            ...values, year: parseInt(period.slice(0, 4)), month: parseInt(period.slice(5, 7)),
            individual_insurance_base: individual_insurance_base.filter(x => x.employee != null && x.amount != null)
        } : null
        dispatch(recalculateTax({ id: taxId, data }))
        setFormState({ visible: false })
    }
    const clientOptions = options?.user?.filter(
        x => options?.billsForTax?.filter(xx => xx.type === 'INVOICE').map(xx => xx.user).includes(x.value)
    ).map(x => ({ label: x.value, value: x.id }))
        .sort((a, b) => (a.label || '').localeCompare(b.label || '')) || []

    const employeeOptions = options?.user?.filter(
        x => options?.billsForTax?.filter(xx => ['SALARY', 'WAGE'].includes(xx.type)).map(xx => xx.user).includes(x.value)
    ).map(x => ({ label: x.value, value: x.id }))
        .sort((a, b) => (a.label || '').localeCompare(b.label || '')) || []

    return (
        <Modal
            title={<Space>
                <CreditCardOutlined style={{ fontSize: '25px' }} />
                Tax settings - {period}
            </Space>}
            open={visible}
            okButtonProps={{ form: 'recalcualte-tax', htmlType: 'submit' }}
            onOk={() => true}
            onCancel={() => { setFormState({ visible: false }) }}
            width={BASE_WIDTH * 1.5}
        >
            <Spin spinning={status === Status.LOADING}>
                <Checkbox checked={newSetting}
                    style={{ marginLeft: 20, marginBottom: 20 }}
                    onChange={(event) => setFormState({ newSetting: event.target.checked })}
                >Recalculate with new input settings</Checkbox>

                <Form
                    id="recalcualte-tax"
                    labelCol={{
                        xs: { span: 16 },
                        sm: { span: 6 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 16 },
                    }}
                    name="recalcualte-tax"
                    onFinish={onFinish}
                    initialValues={{
                        cogs_ratio: 0.7
                    }}
                    scrollToFirstError
                    fields={[
                        {
                            name: 'full_audit_employees',
                            value: fullAudit || []
                        },
                        {
                            name: 'partial_audit_employees',
                            value: partialAudit || []
                        }
                    ]}
                >

                    <Form.Item name="cogs_ratio" label="COGS Ratio"
                        rules={[{ required: newSetting, message: 'Please input the clients!' }]}
                    >
                        <Input disabled={!newSetting} allowClear />
                    </Form.Item>

                    <Form.Item name="clients" label="Clients"
                        rules={[{ required: newSetting, message: 'Please input the clients!' }]}
                    >
                        <Select
                            mode='tags' allowClear
                            disabled={!newSetting} options={clientOptions}
                            filterOption={(inputValue, option) => option.label.includes(inputValue.toUpperCase())}
                        />
                    </Form.Item>

                    <Form.Item name="full_audit_employees" label={`Employees (audit-${fullAudit.length})`}>
                        <Select
                            mode='tags' allowClear disabled={!newSetting}
                            options={employeeOptions.filter(x => !partialAudit.concat(fullInaudit).includes(x.value))}
                            filterOption={(inputValue, option) => option.label.includes(inputValue)}
                            onChange={(values) => setFormState({ fullAudit: values.filter(x => !partialAudit.concat(fullInaudit).includes(x)) })}
                            dropdownRender={(originNode) => {
                                return <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button type='link'
                                            onClick={() => setFormState({
                                                fullAudit: employeeOptions.filter(
                                                    x => !partialAudit.concat(fullInaudit).includes(x.value)
                                                ).map(opt => opt.value)
                                            })}
                                        >Select all</Button>
                                        <Button type='link'
                                            onClick={() => setFormState({ fullAudit: [] })}
                                        >Deselect all</Button>
                                    </div>
                                    {originNode}
                                </>
                            }}
                        />
                    </Form.Item>

                    <Form.Item name="partial_audit_employees" label={`Employees (partial-${partialAudit.length})`}>
                        <Select
                            mode='tags' allowClear disabled={!newSetting}
                            options={employeeOptions.filter(x => !fullAudit.concat(fullInaudit).includes(x.value))}
                            filterOption={(inputValue, option) => option.label.includes(inputValue)}
                            onChange={(values) => setFormState({ partialAudit: values.filter(x => !fullAudit.concat(fullInaudit).includes(x)) })}
                            dropdownRender={(originNode) => {
                                return <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button type='link'
                                            onClick={() => setFormState({
                                                partialAudit: employeeOptions.filter(
                                                    x => !fullAudit.concat(fullInaudit).includes(x.value)
                                                ).map(opt => opt.value)
                                            })}
                                        >Select all</Button>
                                        <Button type='link'
                                            onClick={() => setFormState({ partialAudit: [] })}
                                        >Deselect all</Button>
                                    </div>
                                    {originNode}
                                </>
                            }}
                        />
                    </Form.Item>

                    <Form.Item name="full_inaudit_employees" label={`Employees (inaudit-${fullInaudit.length})`}>
                        <Select
                            mode='tags' allowClear disabled={!newSetting}
                            options={employeeOptions.filter(x => !fullAudit.concat(partialAudit).includes(x.value))}
                            filterOption={(inputValue, option) => option.label.includes(inputValue)}
                            onChange={(values) => setFormState({ fullInaudit: values.filter(x => !fullAudit.concat(partialAudit).includes(x)) })}
                            dropdownRender={(originNode) => {
                                return <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button type='link'
                                            onClick={() => setFormState({
                                                fullInaudit: employeeOptions.filter(
                                                    x => !fullAudit.concat(partialAudit).includes(x.value)
                                                ).map(opt => opt.value)
                                            })}
                                        >Select all</Button>
                                        <Button type='link'
                                            onClick={() => setFormState({ fullInaudit: [] })}
                                        >Deselect all</Button>
                                    </div>
                                    {originNode}
                                </>
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Insurance bases'
                    >
                        {
                            insuranceBase.map((ib, index) => (
                                <Input.Group compact key={index} style={{ marginBottom: '10px' }}>
                                    <Form.Item
                                        name={['individual_insurance_base', index, 'employee']}
                                        noStyle
                                    >
                                        <Select placeholder="employee"
                                            disabled={!newSetting} options={employeeOptions}
                                            style={{ width: `${0.3 * BASE_WIDTH}px` }}
                                            onChange={(value) => {
                                                var updBase = copyObject(insuranceBase)
                                                updBase[index].employee = value
                                                setFormState({ insuranceBase: updBase })
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name={['individual_insurance_base', index, 'amount']}
                                        noStyle
                                    >
                                        <Input disabled={!newSetting} placeholder="amount" type='number'
                                            style={{ width: `${0.3 * BASE_WIDTH}px` }} />
                                    </Form.Item>
                                </Input.Group>
                            ))
                        }
                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>

                            <Typography.Link
                                disabled={!newSetting}
                                onClick={() => {
                                    setFormState({
                                        insuranceBase: [...insuranceBase, { employee: null }]
                                    })
                                }}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                <PlusOutlined /> Add item
                            </Typography.Link>

                            <Typography.Link
                                onClick={() => {
                                    var updBase = copyObject(insuranceBase)
                                    updBase.splice(insuranceBase.length - 1, 1)
                                    setFormState({ insuranceBase: updBase })
                                }}
                                disabled={insuranceBase.length < 2}
                            >
                                <MinusOutlined /> Remove last
                            </Typography.Link>
                        </div>

                    </Form.Item>
                </Form>
            </Spin>


        </Modal>
    )
}

export default TaxRecalculateForm