import { configureStore } from '@reduxjs/toolkit';
import { localStorageMiddleware, loggerMiddleware, socketMiddleware } from '../_middlewares';
import account from '../_reducers/account';
import auth from '../_reducers/auth';
import dashboard from '../_reducers/dashboard';
import socket from '../_reducers/socket';
import storage from '../_reducers/storage';
import reports from '../_reducers/reports';
// import { connectRouter, routerMiddleware } from 'connected-react-router';

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      socket: socket,
      auth: auth,
      account: account,
      dashboard: dashboard,
      storage: storage,
      reports: reports,
      // router: connectRouter(history),
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      // routerMiddleware(history),
      localStorageMiddleware,
      ...(process.env.REACT_APP_FRONTEND_ENV === 'prod' ? [] : [loggerMiddleware]),
      // loggerMiddleware,
      socketMiddleware
    ],
  });
}

const store = makeStore();

export default store;

