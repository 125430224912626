var numeral = require('numeral');

/**
 * 
 * @param {Number} input 
 * @param {String} format 
 * @returns 
 */
export function numberFormat(input, format = '0,0.00') {
    return numeral(input).format(format)
}
/**
 * 
 * @param {Number} amount 
 * @param {String} currency 
 * @param {String} notation 
 * @returns 
 */
export function currencyFormat(amount, currency, notation = 'compact') {
    if (currency == null || amount == null) return amount
    return new Intl.NumberFormat('en-US', { style: 'currency', notation, currency: currency }).format(amount)
}