import { CreditCardOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, message, Modal, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Api, Reducer, Status } from '../../../_helpers';


function TaxPaySalaryForm(props) {
    const dispatch = useDispatch()
    const {
        selectSlice, setState
    } = Reducer();
    const { forms, options } = useSelector(selectSlice);
    const { payment } = forms || {}
    const { BASE_WIDTH } = props
    /**
     * 
     * @param {Object} values 
     */
    const setFormState = (values) => {
        dispatch(setState(
            {
                forms: {
                    ...forms,
                    payment: { ...payment, ...values }
                }
            }
        ));
    }
    /**
     * 
     * @param {Object} values form data
     */
    const onFinish = async (values) => {
        const data = { ...values };
        setFormState({ visible: false });
        dispatch(setState({status: Status.LOADING}))
        await Api().payTaxSalary(payment?.taxId, data).then(res => {
            dispatch(setState({status: Status.SUCCESS}))
            message.info(res.messages);
        }).catch(error => {
            dispatch(setState({status: Status.FAILURE}))
            message.error(JSON.stringify(error));
        })
    }
    const accountOptions = options?.billing_account?.map(opt => ({ label: `${opt.provider} - ${opt.currency}`, value: opt.id }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    const tagOptions = options?.transaction_tag?.map(opt => ({ label: opt, value: opt })) || []

    return (
        <Modal
            title={<Space>
                <CreditCardOutlined style={{ fontSize: '25px' }} />
                Salary payments
            </Space>}
            open={payment?.visible}
            okButtonProps={{ form: 'pay-salary', htmlType: 'submit' }}
            onOk={() => true}
            onCancel={() => { setFormState({ visible: false }) }}
            width={(BASE_WIDTH || 500) * 1.5}
        >
            <Form
                id="pay-salary"
                labelCol={{
                    xs: { span: 16 },
                    sm: { span: 6 },
                }}
                wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 16 },
                }}
                name="pay-salary"
                initialValues={{
                    timestamp: dayjs(),
                    tags: ['salary']
                }}
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="timestamp"
                    label="Date and Time"
                    rules={[{ required: true, message: 'Please input date and time!' }]}

                >
                    <DatePicker showTime allowClear={false} />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Description"
                >
                    <Input.TextArea placeholder="Input description" />
                </Form.Item>

                <Form.Item
                    name='audit_billing_provider'
                    label='Audit account'
                    rules={[{ required: true, message: 'Please select action!', whitespace: true }]}
                >
                    <Select placeholder="Select account"
                        options={accountOptions}
                    />
                </Form.Item>

                <Form.Item
                    name='inaudit_billing_provider'
                    label='Inaudit account'
                    rules={[{ required: true, message: 'Please select action!', whitespace: true }]}
                >
                    <Select placeholder="Select account"
                        options={accountOptions}
                    />
                </Form.Item>

                <Form.Item
                    name="tags"
                    label="Tags"
                >
                    <Select placeholder="Select tags" mode='tags'
                        options={tagOptions}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default TaxPaySalaryForm