import { Space } from 'antd'
import { currencyFormat } from '../../../_helpers'

export const pnlColumns = [
    {
        key: 'period',
        title: 'Period',
        dataIndex: 'period',
        sorter: (a, b) => (a.provider || '').localeCompare(b.provider || ''),
        ellipsis: { showTitle: false },
    },
    {
        key: 'base_currency',
        title: 'Currency',
        dataIndex: 'base_currency',
        sorter: (a, b) => (a.base_currency || '').localeCompare(b.base_currency || ''),
        ellipsis: { showTitle: false },
    },
    {
        key: 'total_revenues',
        title: 'Total Revenues',
        dataIndex: 'total_revenues',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => currencyFormat(item, row?.base_currency)
    },
    {
        key: 'total_salaries',
        title: 'Total Salaries',
        dataIndex: 'total_salaries',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => <Space>
            {currencyFormat(-1.0 * item, row?.base_currency)}
            <span style={{ color: 'red' }}>{Math.round(-100 * item / row.total_revenues)}%</span>
        </Space>
    },
    {
        key: 'total_costs',
        title: 'Total Costs',
        dataIndex: 'total_costs',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => <Space>
            {currencyFormat(-1.0 * item, row?.base_currency)}
            <span style={{ color: 'red' }}>{Math.round(-100 * item / row.total_revenues)}%</span>
        </Space>
    },
    {
        key: 'ebt',
        title: 'EBT',
        dataIndex: 'ebt',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => <Space>
            {currencyFormat(item, row?.base_currency)}
            <span style={{ color: 'green' }}>{Math.round(100 * item / row.total_revenues)}%</span>
        </Space>
    },
    {
        key: 'retain',
        title: 'Retain',
        dataIndex: 'retaining_rate',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => <Space>
            {currencyFormat(row.ebt * row.retaining_rate, row?.base_currency)}
            <span style={{ color: 'green' }}>{
                Math.round(100 * row.ebt * item / row.total_revenues)
            }%</span>
        </Space>
    },
    {
        key: 'profit_share',
        title: 'Profit Share',
        dataIndex: 'profit_share',
        sorter: (a, b) => a - b,
        ellipsis: { showTitle: false },
        render: (item, row, index) => <Space>
            {currencyFormat(row.ebt * (1 - row.retaining_rate) / row.share_count, row?.base_currency)}
            <span style={{ color: 'green' }}>{
                Math.round(100 * row.ebt * (1 - row.retaining_rate) / (row.share_count * row.total_revenues))
            }%</span>
        </Space>
    },
]