import dayjs from 'dayjs';
import TagInfo from '../../display/TagInfo';

export const getTimebookColumns = (content = []) => (
    [
        ...periodColumns,
        ...content,
        ...statusColumns
    ]
)

const periodColumns = [
    {
        title: 'Date',
        key: 'date',
        dataIndex: 'date',
        fixed: 'left',
        width: '100px',
        sorter: (a, b) => (a.date || '').localeCompare(b.date || ''),
        render: (text, record, index) => <span
            className={text === dayjs(new Date()).format('YYYY-MM-DD') ? 'highlighted-cell' : ''}
        >{text}</span>
    },
    {
        title: 'Weekday',
        key: 'week_day',
        dataIndex: 'week_day',
        fixed: 'left',
        width: '100px',
        sorter: (a, b) => (a.date || '').localeCompare(b.week_day || ''),
    },
]

const statusColumns = [
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        fixed: 'right',
        width: '100px',
        sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
        render: (items) => <TagInfo {...{ items }} />
    }
]