import React from 'react'
import { transactionColumns } from './columns'
import RecordActions from '../_shared/RecordActions'
import BaseTable from '../_base/BaseTable'

export function TransactionTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      ...transactionColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`${record.date} ${record.user ? record.user : ''}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Sent to processing', 'Mark as success',
              'Mark as failure', 'Remove'
            ]
          }}
        />
      }
    ],
    searchKeys: ['date', 'related_party', 'description', 'billing_account', 'currency', 'status_tags'],
    arrayKeys: ['status_tags'],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
