import { StarFilled, StarOutlined } from '@ant-design/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Reducer } from '../../../_helpers';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { contractColumns } from './columns';

export function ContractTable(props) {
  const dispatch = useDispatch();
  const { makeContractDefault } = Reducer()
  const { display } = props
  const tableProps = {
    columns: [
      {
        key: 'is_default',
        title: '',
        dataIndex: 'is_default',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.is_default - b.is_default,
        width: 40,
        render: (item, record, index) => {
          return item === true ?
            <StarFilled
              className='icon-switch-button'
              style={{ cursor: 'not-allowed' }}
            /> :
            <StarOutlined
              className='icon-switch-button'
              onClick={(event) => {
                event.preventDefault(); event.stopPropagation();
                dispatch(makeContractDefault({ id: record.id }))
              }}
            />
        }
      },
      ...contractColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`[${record.name}] ${record.product}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Mark as invalid', 'Get sign-up link'
            ]
          }}
        />
      }
    ],
    searchKeys: [
      'name', 'product', 'clients', 'editors', 'qcs', 'status'
    ],
    arrayKeys: [
      'clients', 'editors', 'qcs'
    ],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
