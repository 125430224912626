import { apiRole, apiType, currentSearch, Status } from "../_helpers";
import { setNotifications } from "../_reducers/account";
import { handleStream as dashboardStream } from "../_reducers/dashboard";
import { handleStream as storageStream } from "../_reducers/storage";

import { getJobTree, selectFolder } from "../_actions/storage";
import { buffer, disconnect, setState } from "../_reducers/socket";

let socket = null;

export const socketMiddleware = (store) => (next) => (action) => {
    /**
     * 
     * @param {*} store 
     * @returns 
     */
    const onOpen = (store) => (event) => {
    }
    /**
     * 
     * @param {*} store 
     * @returns 
     */
    const onError = (store) => (event) => {
        store.dispatch(setState({ status: Status.FAILURE }))
    }
    /**
     * 
     * @param {*} store 
     * @returns 
     */
    const onClose = (store) => () => store.dispatch(disconnect())
    /**
     * 
     * @param {*} store 
     * @returns 
     */
    const onMessage = (store) => (event) => {
        const query = currentSearch()
        const payload = JSON.parse(event.data);
        const notification = JSON.parse(payload?.notification)
        const data = JSON.parse(payload?.data)
        if (apiType() === 'dashboard') {
            if (query.document === notification.document) {
                const user = store.getState().account?.info || {}
                const qcAssigned = apiRole() === 'qc' ? data.product_list?.find(x => x.qc === user.id) != null : null
                store.dispatch(dashboardStream({ data, qcAssigned }));
            }
        }
        if (apiType() === 'storage') {
            const statusUpdated = ["order_create", "order_deleted", "order_restored"].includes(notification.content_type)
            if (statusUpdated) {
                store.dispatch(getJobTree(''))
            }
            const currentId = store.getState().storage.record?.id || data.id
            if (currentId === data.id) {
                store.dispatch(selectFolder({ id: data.id, path: currentSearch().path },))
            }
            store.dispatch(storageStream({ data }));
        }

        store.dispatch(setNotifications({ data: [notification] }))
    }

    switch (action.type) {
        case 'socket/connect':
            //connect to the remote host
            socket = new WebSocket(action.payload.host)

            // websocket handlers
            socket.onmessage = onMessage(store)
            socket.onclose = onClose(store)
            socket.onopen = onOpen(store)
            socket.onerror = onError(store)
            break;
        case 'socket/disconnect':
            // socket = null
            break;
        case 'socket/subscribe':
            if (socket?.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    "method": "SUBSCRIBE",
                    "params": [action.payload.stream],
                    "id": 1
                }))
            } else {
                store.dispatch(buffer(action.payload.host, action.payload.stream))
            }
            break;
        case 'socket/unsubscribe':
            if (socket !== null) {
                socket.send({
                    "method": "UNSUBSCRIBE",
                    "params": [action.payload.stream],
                    "id": action.payload.id
                })
            }
            break;
        default: break;
    }
    return next(action);

}
