import TagInfo from '../../display/TagInfo';

export const userColumns = [
   {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
      ellipsis: { showTitle: false },
   },
   {
      key: 'short_name',
      title: 'Short Name',
      dataIndex: 'short_name',
      sorter: (a, b) => (a.short_name || '').localeCompare(b.short_name || ''),
      ellipsis: { showTitle: false },
   },
   {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => (a.email || '').localeCompare(b.email || ''),
      ellipsis: { showTitle: false },
   },
   {
      key: 'roles',
      title: 'Roles',
      dataIndex: 'roles',
      ellipsis: { showTitle: false },
      render: (text, record, index) => record.roles.join(', ')
   },
   {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
      ellipsis: { showTitle: false },
      render: (items) => <TagInfo {...{ items }} />
   },
]