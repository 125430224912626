import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../_helpers";

/**
 * @type {SocketState}
 */
const initialState = {
    streams: [], attemps: 0, status: Status.IDLE
};

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        /**
         * Set state
         * @param {import('@reduxjs/toolkit').Draft<ReportState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload }
        },
        /**
         * Connect to websocket
         * @param {import('@reduxjs/toolkit').Draft<SocketState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        connect(state) {
            state.attemps += 1
            state.status = Status.SUCCESS
        },
        /**
         * Disconnect from websocket
         * @param {import('@reduxjs/toolkit').Draft<SocketState>} state
         */
        disconnect(state) {
            state.status = Status.IDLE
        },
        /**
         * Add stream to queue
         * @param {import('@reduxjs/toolkit').Draft<SocketState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        buffer(state, action) {
            const { stream } = action.payload
            if (state.streams.findIndex(s => s.param === stream) < 0) {
                state.streams.push({ param: stream, status: false })
            }
        },
        /**
         * Subscribe to a stream
         * @param {import('@reduxjs/toolkit').Draft<SocketState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        subscribe(state, action) {
            const { stream } = action.payload
            const idx = state.streams.findIndex(s => s.param === stream)
            if (idx > -1) state.streams[idx].status = true
            else {
                state.streams.push({param: action.stream, status: true })
            }
        },
        /**
         * Unsubscribe from a stream
         * @param {import('@reduxjs/toolkit').Draft<SocketState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        unsubscribe(state, action) {
            const { stream } = action.payload
            const idx = state.streams.findIndex(s => s.param === stream)
            if (idx > -1) state.streams.splice(idx, 1)
        },
    }
})

export const { setState, connect, disconnect, buffer, subscribe, unsubscribe } = socketSlice.actions;

/**
 * Get account slice
 *
 * @param {Object} state
 * @returns {SocketState}
 */
export const selectSocketSlice = (state) => state.socket;

export default socketSlice.reducer;

