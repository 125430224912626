import ArrayInfo from '../../display/ArrayInfo';
import TagInfo from '../../display/TagInfo';
import TooltipInfo from '../../display/TooltipInfo';

export const orderColumns = [
    {
        key: 'date',
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => (a.date || '').localeCompare(b.date || ''),
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={[items]} />
    },
    {
        key: 'client_name',
        title: 'Client',
        dataIndex: 'client_name',
        sorter: (a, b) => (a.client_name || '').localeCompare(b.client_name || ''),
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={[items]} />
    },
    {
        key: 'job_ref',
        title: 'Job Reference',
        dataIndex: 'job_ref',
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={[items]} />
    },
    {
        key: 'notes',
        title: 'Notes ',
        dataIndex: 'notes',
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={[items]} />
    },
    {
        key: 'products',
        title: 'Products',
        dataIndex: 'products',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'amounts',
        title: 'Amounts',
        dataIndex: 'amounts',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'prices',
        title: 'Prices',
        dataIndex: 'prices',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'editors',
        title: 'Editors',
        dataIndex: 'editors',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'edit_rates',
        title: 'Edit Rates',
        dataIndex: 'edit_rates',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items, render: new Intl.NumberFormat('en-US', { notation: 'compact' }).format }} />
    },
    {
        key: 'qcs',
        title: 'QCs',
        dataIndex: 'qcs',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'qc_rates',
        title: 'QC Rates',
        dataIndex: 'qc_rates',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items, render: new Intl.NumberFormat('en-US', { notation: 'compact' }).format }} />
    },
    {
        key: 'pr_editors',
        title: 'PR.Editors',
        dataIndex: 'pr_editors',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'pr_qcs',
        title: 'PR.QCs',
        dataIndex: 'pr_qcs',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        width: '7%',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
    {
        key: 'product_status',
        title: 'PR.Status',
        dataIndex: 'product_status',
        width: '7%',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
    {
        key: 'request_status',
        title: 'RQ.Status',
        dataIndex: 'request_status',
        width: '7%',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    },
    {
        key: 'billing_status',
        title: 'Bill Status',
        dataIndex: 'billing_status',
        ellipsis: { showTitle: false },
        render: (items) => <TagInfo {...{ items }} />
    }
]
