import React from 'react';
import BaseTable from '../_base/BaseTable';
import { roleParamColumns } from './columns';

export function RoleParamTable(props) {
  // const { display } = props
  const tableProps = {
    columns: [
      ...roleParamColumns,
    ],
    searchKeys: ['name', 'role', 'type', 'user_updatable'],
    arrayKeys: [],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
