import React from 'react'
import BaseTable from '../_base/BaseTable'
import RecordActions from '../_shared/RecordActions'
import { apiVarcolumns } from './columns'

export function APIVarTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      ...apiVarcolumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={record.email}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Delete'
            ]
          }}
        />
      }
    ],
    searchKeys: ['name'],
    arrayKeys: [],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
