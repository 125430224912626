import { Tag } from "antd"
import { currencyFormat } from "../../../_helpers"
import { statusTags } from "../../../_styles/tag.styles"

export const accountColumns = [
    {
        key: 'provider',
        title: 'Provider',
        dataIndex: 'provider',
        width: '15%',
        sorter: (a, b) => (a.provider || '').localeCompare(b.provider || ''),
    },
    {
        key: 'currency',
        title: 'Currency',
        dataIndex: 'currency',
        width: '10%',
        sorter: (a, b) => (a.currency || '').localeCompare(b.currency || ''),
    },
    {
        key: 'starting_balance',
        title: 'Initial Balance',
        dataIndex: 'starting_balance',
        width: '15%',
        render: (item, record, index) => currencyFormat(record?.starting_balance, record?.currency, 'standard')
    },
    {
        key: 'inflow',
        title: 'Inflow',
        dataIndex: 'inflow',
        width: '15%',
        render: (item, record, index) => (
            <span style={{ color: 'green' }}>
                {currencyFormat(record?.inflow, record?.currency, 'standard')}
            </span>
        )
    },
    {
        key: 'outflow',
        title: 'Outflow',
        dataIndex: 'outflow',
        width: '15%',
        render: (item, record, index) => (
            <span style={{ color: 'red' }}>
                {currencyFormat(record?.outflow, record?.currency, 'standard')}
            </span>
        )
    },
    {
        key: 'ending_balance',
        title: 'Current Balance',
        dataIndex: 'ending_balance',
        width: '15%',
        sorter: (a, b) => a.ending_balance > b.ending_balance,
        render: (item, record, index) => (
            <span style={{ fontWeight: 500 }}>
                {currencyFormat(record?.ending_balance, record?.currency, 'standard')}
            </span>
        )
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        width: '15%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: text => {
            return <Tag color={statusTags.find(s => s.values.includes(text?.toLowerCase()))?.color || 'default'} key={text}>
                {text?.toUpperCase()}
            </Tag>
        }
    }
]