import { Divider, Typography } from 'antd';
import React from 'react';
import { currencyFormat } from '../../../_helpers';
import TagInfo from '../../display/TagInfo';
import BaseTable from '../_base/BaseTable';

function ProductSubTable(props) {
  const tableProps = {
    columns: [
      {
        key: 'title',
        title: 'Title',
        dataIndex: 'title',
        width: '25%',
        sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
      },
      {
        key: 'description',
        title: 'Description',
        dataIndex: 'description',
        width: '40%',
        className: 'long-text-cell'
      },
      {
        key: 'price',
        title: 'Price (per final image/video)',
        dataIndex: 'price',
        width: '10%',
        sorter: (a, b) => (a.price?.amount || 0) - (b.price?.amount || 0),
        render: (item, record) => item ? currencyFormat(item?.amount, item?.currency, 'standard') : null
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        width: '10%',
        sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
        render: (items) => <TagInfo {...{ items }} />
      }
    ],
    searchIndex: ['title'],
    arraySearchIndex: [],
    pagination: { defaultPageSize: 10 }
  }

  return (
    <div style={{ padding: 20 }}>

      <div>
        <Typography.Title level={4}>
          Editing Product Information
        </Typography.Title>
        <Typography.Text italic>
          The following table contains the costs that we currently apply to all of your orders. <br />
          If you would like to arrange another price list which meets your requirements, please feel free to contact <a href="mailto:support@phtstudio.com">support@phtstudio.com</a>.
        </Typography.Text>
      </div>
      <div>
        <Divider />
        <BaseTable
          {...props}
          tableProps={tableProps}
        />
      </div>


    </div>
  )
}

export default ProductSubTable