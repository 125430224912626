import React from 'react';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { clientColumns } from './columns';

export function ClientTable(props) {
  const { display } = props
  const tableProps = {
    columns: [
      ...clientColumns,
      {
        key: 'action',
        title: '',
        dataIndex: null,
        width: 50,
        render: (text, record, index) => <RecordActions
          title={`${record.date} ${record.user}`}
          record={record}
          display={{
            actions: display?.actions || [
              'View and edit', 'Mark as invalid', 'Mark as declined', 'Restore to set', 'Get sign-up link'
            ]
          }}
        />
      }
    ],
    searchKeys: [
      'name',
      'contact_first_name', 'contact_last_name', 'contact_email', 'contact_website',
      'agent_name', 'status'
    ],
    arrayKeys: [
      'contact_first_name', 'contact_last_name', 'contact_email', 'contact_website'
    ],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
