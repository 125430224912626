import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api, serializeError } from "../_helpers";

/**
 * @param {String} document 
 * @param {Object} query 
 * @param {Object} data 
 */
export const create = createAsyncThunk(
    'reports/create',
    async ({ document, query, data }, _) => await Api().create(document, query, data),
    { serializeError }
);
/**
 * Common API
 * @param {String} document 
 * @param {Array<String>} tags 
 * @returns 
 */
export const getBillsForTax = createAsyncThunk(
    'reports/getBillsForTax',
    async ({ data }, _) => await Api().searchTags('billing', data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
 */
export const recalculateTax = createAsyncThunk(
    'reports/update',
    async ({ id, data }, _) => await Api().recalculateTax(id, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
 */
export const getStatistics = createAsyncThunk(
    'reports/statistics',
    async ({ name, data }, _) => await Api().statistics(name, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
 */
export const _export = createAsyncThunk(
    'reports/update',
    async ({ document, data }, _) => await Api().export(document, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Object} data 
*/
export const reports = createAsyncThunk(
    'reports/data',
    async ({ document, data }, _) => await Api().reports(document, {...data, clients: JSON.parse(localStorage.getItem('reports/sales/clients'))}),
    { serializeError }
);