import React from 'react'
import { logColumns } from './columns';
import BaseTable from '../_base/BaseTable';

export function LogTable(props) {
  const tableProps = {
    columns: [
      ...logColumns,
    ],
    searchKeys: [
      'call_type', 'method', 'ip_address', 'user_email'
    ],
    arrayKeys: [],
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
    />
  )
}
