import axios from "axios";
import { config } from "../app/config";
import requests from "./_base";

const { apiRoot } = config

export const Auth = {
    /**
     * Login with email and password
     *
     * @param {String} username
     * @param {String} password
     * @returns {Promise<UserAuth>}
     */
    login: async (username, password) => {
        let formData = new FormData()
        formData.append('username', username);
        formData.append('password', password);
        var config = {
            headers: { "content-type": "multipart/form-data" },
        };
        return await axios.post(`${apiRoot}/auth/oauth/login`, formData, config)
            .then(function (response) {
                return response.data
            })
            .catch((error) => {
                const { response } = error
                const errors = { errors: { [response.status]: [response.data.detail || response.statusText] } };
                throw errors;
            })
    },
    /**
     * Register with username, email and password
     *
     * @param {Object} data
     * @returns {Promise<UserAuth>}
     */
    register: (data) => requests.post('/auth/oauth/register', {}, data),
    /**
     * Register client and send test edits requet
     *
     * @param {Object} data
     * @returns {Promise<UserAuth>}
     */
    registerClient: (data) => requests.post('/auth/oauth/register-client', {}, data),
    /**
     * Validate current jwt token
     * 
     * @returns 
     */
    validateToken: () => requests.get(`/auth/oauth/validate`),
    /**
     * Verify email with sent token
     *
     * @param {String} token
     * @returns {Promise<UserAuth>}
     */
    verifyEmail: (token) => requests.post(`/auth/oauth/verify/${token}`),
    /**
     * Initialize user role
     *
     * @param {str}  role
     * @param {str|null}  location
     * @returns {Promise<UserAuth>}
     */
    initializeRole: (role, location) => requests.post(`/auth/oauth/role`, { role, location }),
    /**
     * Get reset token
     *
     * @param {String} key
     * @param {String} type
     * @param {Object} data
     * @returns {Promise<UserAuth>}
     */
    getResetToken: (key, type, data) => requests.post(`/auth/reset-token/${type}/${key}`, {}, data),
    /**
     * Validate reset token
     *
     * @param {String} token
     * @returns {Promise<UserAuth>}
     */
    validateResetToken: (token) => requests.get(`/auth/reset-token/validate/${token}`),
    /**
     * Verify reset token
     *
     * @param {String} type
     * @param {String} token
     * @param {Object} data
     * @returns {Promise<UserAuth>}
     */
    verifyResetToken: (type, token, data) => requests.post(`/auth/reset-token/verify/${type}/${token}`, {}, data),

}
