import React from 'react'
import { userColumns } from './columns'
import RecordActions from '../_shared/RecordActions'
import BaseTable from '../_base/BaseTable'

export function UserTable(props) {
    const { display } = props
    const tableProps = {
        columns: [
            ...userColumns,
            {
                key: 'action',
                title: '',
                dataIndex: null,
                width: 50,
                render: (text, record, index) => <RecordActions
                    title={record.email}
                    display={{
                        actions: display?.actions || [
                            'View and edit', 'Activate', 'Deactivate', 'Lock', 'Unlock', 'Delete'
                        ]
                    }}
                    record={record}
                />
            }
        ],
        searchKeys: ['name', 'short_name', 'email', 'roles', 'status'],
        arrayKeys: ['roles'],
    }

    return (
        <div style={{ width: '99%' }}>
            <BaseTable
                {...props}
                tableProps={tableProps}
            />
        </div>

    )
}
