import { currencyFormat } from '../../../_helpers';
import TagInfo from '../../display/TagInfo';

export const transactionColumns = [
    {
       key: 'date',
       title: 'Date',
       dataIndex: 'date',
       width: '15%',
       sorter: (a, b) => (a.date || '').localeCompare(b.date || ''),
       ellipsis: { showTitle: false }
    },
    {
       key: 'related_party',
       title: 'Related Party',
       dataIndex: 'related_party',
       width: '10%',
       sorter: (a, b) => (a.related_party || '').localeCompare(b.related_party || ''),
       ellipsis: { showTitle: false }
    },
    {
       key: 'description',
       title: 'Description',
       dataIndex: 'description',
       sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
       ellipsis: { showTitle: false }
    },
    {
       key: 'billing_account',
       title: 'Account',
       dataIndex: 'billing_account',
       sorter: (a, b) => (a.billing_account || '').localeCompare(b.billing_account || ''),
       ellipsis: { showTitle: false }
    },
    {
       key: 'amount',
       title: 'Amount',
       dataIndex: 'amount',
       width: '10%',
       sorter: (a, b) => a.amount > b.amount,
       ellipsis: { showTitle: false },
       render: (item, record, index) => <span style={{ color: `${record.inflow ? 'green' : 'red'}` }}>{currencyFormat(record?.amount, record?.currency)}</span>
    },
    {
       key: 'currency',
       title: 'Currency',
       dataIndex: 'currency',
       width: '10%',
       sorter: (a, b) => (a.currency || '').localeCompare(b.currency || ''),
       ellipsis: { showTitle: false }
    },
    {
       key: 'status_tags',
       title: 'Tags',
       dataIndex: 'status_tags',
       width: '30%',
       ellipsis: { showTitle: false },
       render: (items) => <TagInfo {...{ items }} />
    },
 ]