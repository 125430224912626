export const apiVarcolumns = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => (a.provider || '').localeCompare(b.provider || ''),
    },
    {
        key: 'value',
        title: 'Value',
        dataIndex: 'value',
        ellipsis: { showTitle: false },
        render: (text) => JSON.stringify(text)
    },
]