import * as dashboardActions from "../_actions/dashboard";
import * as dashboardReducer from "../_reducers/dashboard";
import * as storageActions from "../_actions/storage";
import * as storageReducer from "../_reducers/storage";
import * as reportActions from "../_actions/reports";
import * as reportReducer from "../_reducers/reports";
import { apiType } from "./constants";

/**
 * Get corresponding actions based on API type ('dashboard'|'storage')
 * @returns {Object}
 */
export function Reducer() {
    switch (apiType()) {
        case "storage": return {
            ...storageReducer,
            ...storageActions,
            selectSlice: storageReducer.selectStorageSlice
        }
        case "reports": return {
            ...reportReducer,
            ...reportActions,
            selectSlice: reportReducer.selectReportSlice
        }
        default: return {
            ...dashboardReducer, 
            ...dashboardActions,
            selectSlice: dashboardReducer.selectDashboardSlice
        }
    }
}

