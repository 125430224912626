import { Space, Tooltip } from 'antd';
import { numberFormat, timestampToDate } from '../../../_helpers';
import TagInfo from '../../display/TagInfo';

export const campaignColumns = [
   {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
      width: '30%',
      ellipsis: { showTitle: false },
      render: text => <Tooltip title={text}
         style={{ textOverflow: 'ellipsis' }}
      >{text}</Tooltip>
   },
   {
      key: 'send_at',
      title: 'Published at',
      dataIndex: 'send_at',
      sorter: (a, b) => (a.send_at || 0) - (b.send_at || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => item ?
         <Tooltip title={timestampToDate(item, 'HH:mm:ss MMM D YYYY')}
            style={{ textOverflow: 'ellipsis' }}
         >{timestampToDate(item, 'MMM D YYYY')}
         </Tooltip> : null
   },
   {
      key: 'agent_name',
      title: 'Sales agent',
      dataIndex: 'agent_name',
      sorter: (a, b) => (a.agent_name || '').localeCompare(b.agent_name || ''),
      ellipsis: { showTitle: false },
   },
   {
      key: 'sent',
      title: 'Sent',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.sent || 0) - (b.statistics?.sent || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <span style={{ fontWeight: 500 }}>{numberFormat(item.sent, '0,0')}</span>
   },
   {
      key: 'delivered',
      title: 'Delivered',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.delivered || 0) - (b.statistics?.delivered || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <Space size='small'>
         <span style={{ color: 'green' }}>{numberFormat(item.delivered, '0,0')} </span>
         <Tooltip
            title={<>{numberFormat(100 * item.delivered / item.sent, '0,0.00')}%</>}
         >
            <span style={{ color: 'green', fontSize: 12, fontWeight: 400 }}>({
               numberFormat(100 * item.delivered / item.send, '0,0')
            }%)</span>
         </Tooltip>
      </Space>
   },
   {
      key: 'bounced',
      title: 'Bounced',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.bounced || 0) - (b.statistics?.bounced || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <span style={{ color: 'orange' }}>{numberFormat(item.bounced, '0,0')}</span>
   },
   {
      key: 'spam',
      title: 'Spam',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.spam || 0) - (b.statistics?.spam || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <span style={{ color: 'red' }}>{numberFormat(item.spam, '0,0')}</span>
   },
   {
      key: 'opened',
      title: 'Opened',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.opened || 0) - (b.statistics?.opened || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <Space size='small'>
         <span style={{ color: 'blue' }}>{numberFormat(item.opened, '0,0')} </span>
         <Tooltip
            title={<>{numberFormat(100 * item.opened / record.statistics?.sent, '0,0.00')}%</>}
         >
            <span style={{ color: 'blue', fontSize: 12, fontWeight: 400 }}>({
               numberFormat(100 * item.opened / record.statistics?.sent, '0,0')
            }%)</span>
         </Tooltip>

      </Space>
   },
   {
      key: 'clicked',
      title: 'Clicked',
      dataIndex: 'statistics',
      sorter: (a, b) => (a.statistics?.clicked || 0) - (b.statistics?.clicked || 0),
      ellipsis: { showTitle: false },
      render: (item, record, index) => <Space size='small'>
         <span style={{ color: 'purple' }}>{numberFormat(item.clicked, '0,0')} </span>
         <Tooltip
            title={<>{numberFormat(100 * item.clicked / item.sent, '0,0.00')}%</>}
         >
            <span style={{ color: 'purple', fontSize: 12, fontWeight: 400 }}>({
               numberFormat(100 * item.clicked / item.sent, '0,0')
            }%)</span>
         </Tooltip>

      </Space>
   },
   {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      ellipsis: { showTitle: false },
      render: (items) => <TagInfo {...{ items }} />
   },
]