import { MoreOutlined } from '@ant-design/icons';
import { Button, List, Popover, Typography } from 'antd';
import React, { useState } from 'react';

export function ListActions(props) {
    var [visibile, setVisible] = useState(false)
    const { id, title, width, placement, buttonSize, buttonIcon, actions } = props
   

    return (
        <Popover
            content={
                <List id={`${id}-popup`} style={{ border: 'None', width: width }}>
                    {actions.map((opt, i) => {
                        const optID = opt.title?.replaceAll(' ', '-').toLowerCase() || id;
                        return opt.group ? [
                            <List.Item key={`${i}-1`} style={{ height: '32px', marginTop: i === 0 ? 0 : 5 }}>
                                <Typography.Text type="secondary" style={{ fontSize: 12 }}>{opt.group}</Typography.Text>
                            </List.Item>
                        ] : [
                            <List.Item key={`${i}-2`} style={{ height: '32px', border: 'None', marginLeft: 10, padding: 0 }}>
                                {

                                    opt.href == null ?
                                        <Typography.Text id={optID}
                                            className={opt.disabled ? '' : 'text-button'}
                                            disabled={opt.disabled}
                                            onClick={(event) => {
                                                event.preventDefault(); event.stopPropagation();
                                                if (opt.disabled) return
                                                setVisible(false); opt.onClick()
                                            }}
                                        >
                                            <span style={{ marginRight: 10 }}>{opt.icon}</span>
                                            <span>{opt.title}</span>
                                        </Typography.Text> :
                                        <a  
                                            id={optID}
                                            className={opt.disabled ? '' : 'text-button'}
                                            disabled={opt.disabled}
                                            href={opt.href}
                                            onClick={(event) => { setVisible(false) }}
                                        >
                                            <span style={{ marginRight: 10 }}>{opt.icon}</span>
                                            <span>{opt.title}</span>
                                        </a>
                                }
                            </List.Item>
                        ]
                    })}
                </List>
            }
            placement={placement ? placement : "bottomLeft"}
            title={title || ''}
            trigger="click"
            open={visibile}
            onOpenChange={setVisible}
        >
            <Button id={id || 'list-actions-button'} size={buttonSize || "small"}
                style={{ border: 'None', backgroundColor: 'transparent', padding: 0 }}
                icon={buttonIcon || <MoreOutlined />}
                onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
            />
        </Popover>
    )
}
