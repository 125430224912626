import { Card, Empty, Space, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Reducer } from '../../../_helpers';
import TagInfo from '../../display/TagInfo';

function ProductCard(props) {
    const dispatch = useDispatch();
    const { takeQcs, returnQcs } = Reducer()
    const [activeTabKey, setActiveTabKey] = useState('products');
    var { primary_cloud, other_input_url } = props?.record?.file_storage || {}

    const productColumns = [
        {
            title: 'Product',
            dataIndex: 'product_title',
            key: 'product',
            // width: '400px',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            // width: '50px',
        },
        {
            title: 'Editor',
            dataIndex: 'editor_name',
            key: 'editor',
            // width: '120px'
        },
        {
            title: 'QC',
            dataIndex: 'qc_name',
            key: 'qc',
            // width: '120px'
        },
        {
            title: 'Status',
            dataIndex: 'last_action',
            key: 'last_action',
            render: items => <TagInfo {...items} />
        },
        {
            title: '',
            key: 'actions',
            render: (text, record, index) => <Space split={<span style={{ color: 'grey' }}>|</span>}>
                <Typography.Link disabled={record.qc != null}
                    onClick={() => {
                        dispatch(takeQcs({ data: [record.id], type: 'task' }))
                    }}
                >take</Typography.Link>
                <Typography.Link disabled={record.qc !== props.user?.info?.id}
                    onClick={() => {
                        dispatch(returnQcs({ data: [record.id], type: 'task' }))
                    }}
                >return</Typography.Link>
            </Space>
        },
    ]

    const tabList = [
        {
            key: 'products',
            tab: 'Products'
        },
        {
            key: 'instructions',
            tab: 'Instructions'
        }
    ]

    const contentList = {
        products: <div>
            <span style={{ width: '100%' }}></span>
            <Table size='small' bordered={false} pagination={false}
                columns={productColumns}
                dataSource={props.record.product_list?.map(
                    (prt, index) => ({ ...prt, key: prt.id || index } || [])
                )}
            />
        </div>,
        instructions: props.record.instructions ?
            <p style={{
                height: 100, resize: 'vertical', whiteSpace: 'pre-wrap', overflow: 'scroll',
                textOverflow: 'unset', background: 'whitesmoke'
            }}>{props.record.instructions}</p> :
            <Empty style={{ height: 40 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }

    return (
        <Card
            style={{
                width: '90%',
                minWidth: 500,
                margin: 'auto',
                marginBottom: 20,
                fontSize: 12
            }}
            // title="Card title"
            extra={
                <>
                    {primary_cloud === 'Others' && <Space>
                        {
                            other_input_url?.map((item, i) => <Typography.Link key={i} href={item}>{`files ${i + 1}`}</Typography.Link>)
                        }
                    </Space>}
                </>
            }
            tabList={tabList}
            tabProps={{ size: 'small' }}
            activeTabKey={activeTabKey}
            onTabChange={(key) => {
                setActiveTabKey(key);
            }}
        >
            {contentList[activeTabKey]}
        </Card>
    )
}

export default ProductCard