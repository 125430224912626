import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api, serializeError } from "../_helpers";

/**
 * @param {String} document 
 * @param {Object} query 
 * @param {Object} data 
 */
export const update = createAsyncThunk(
    'storage/update',
    async ({ document, query, data }, _) => await Api().update(document, query, data),
    { serializeError }
);
/**
 * @param {String} id  order ID
 * @param {Array<String>} data s3 keys
 */
export const draftToActive = createAsyncThunk(
    'storage/update',
    async ({ id, data }, _) => await Api().createFolders(id, data),
    { serializeError }
);
/**
 * @param {Boolean} unset 
 * @param {Object} query 
 * @param {Array<String>} data record ids
 */
export const updateOrderHistory = createAsyncThunk(
    'storage/update',
    async ({ unset, query, data }, _) => await Api().updateOrderHistory(unset, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const addRequest = createAsyncThunk(
    'storage/update',
    async ({ id, query, data }, _) => await Api().addRequest(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const updateRequest = createAsyncThunk(
    'storage/update',
    async ({ id, query, data }, _) => await Api().updateRequest(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const addRequestComment = createAsyncThunk(
    'storage/update',
    async ({ id, query, data }, _) => await Api().addRequestComment(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const modifyRequestComment = createAsyncThunk(
    'storage/update',
    async ({ id, query, data }, _) => await Api().modifyRequestComment(id, query, data),
    { serializeError }
);
/**
 * @param {('pending'|'working'|'delivered'|'canceled')} status 
 * @param {Object} query 
 * @param {Object} data 
 */
export const setRequestStatus = createAsyncThunk(
    'storage/update',
    async ({ status, query, data }, _) => await Api().setRequestStatus(status, query, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const completeQcs = createAsyncThunk(
    'storage/update',
    async ({ type, data }, _) => await Api().completeQcs(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const reviewQcs = createAsyncThunk(
    'storage/update',
    async ({ type, data }, _) => await Api().reviewQcs(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const completeRequests = createAsyncThunk(
    'storage/update',
    async ({ type, data }, _) => await Api().completeRequests(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const reviewRequests = createAsyncThunk(
    'storage/update',
    async ({ type, data }, _) => await Api().reviewRequests(type, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const completeEdits = createAsyncThunk(
    'storage/update',
    async ({ name, data }, _) => await Api().completeEdits(name, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const reviewEdits = createAsyncThunk(
    'storage/update',
    async ({ name, data }, _) => await Api().reviewEdits(name, data),
    { serializeError }
);
/**
 * @param {String} id  order ID
 * @param {Array<String>} data s3 keys
 */
export const createFolders = createAsyncThunk(
    'storage/createFolders',
    async ({ id, data }, _) => await Api().createFolders(id, data),
    { serializeError }
);
/**
 * @param {String} id  order ID
 * @param {Array<String>} data s3 keys
 */
export const deleteObjects = createAsyncThunk(
    'storage/deleteObjects',
    async ({ id, data }, _) => await Api().deleteObjects(id, data),
    { serializeError }
);
/**
 * @param {String} id order ID
 * @param {Object} [query] 
 * @param {String} [query.source] s3 key
 * @param {String} [query.name]
 */
export const renameObject = createAsyncThunk(
    'storage/renameObject',
    async ({ id, source, name }, _) => await Api().renameObject(id, { source, name }),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
*/
export const storage = createAsyncThunk(
    'storage/data',
    async ({ id, data }, _) => await Api().storage(id, data),
    { serializeError }
);
/**
 * @param {String} key search key
*/
export const getJobTree = createAsyncThunk(
    'storage/jobTree[loading]',
    async ({ key }, _) => await Api().jobTree(key),
    { serializeError }
);
/**
 * @param {String} id order ID
*/
export const selectJob = createAsyncThunk(
    'storage/job',
    async ({ id }, _) => await Api().job(id),
    { serializeError }
);
/**
 * @param {String} id order ID
 * @param {String} path s3 path
*/
export const selectFolder = createAsyncThunk(
    'storage/folder',
    async ({ id, path }, _) => await Api().folder(id, path),
    { serializeError }
);
