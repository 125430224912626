import React from 'react';
import TagInfo from '../../display/TagInfo';
import TooltipInfo from '../../display/TooltipInfo';


export const clientColumns = [
    {
        key: 'name',
        title: 'Client name',
        dataIndex: 'name',
        sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        ellipsis: { showTitle: false },
    },
    {
        key: 'contact_first_name',
        title: 'First names',
        dataIndex: 'contact_first_name',
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={items} />,
    },
    {
        key: 'contact_last_name',
        title: 'Last names',
        dataIndex: 'contact_last_name',
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={items} />
    },
    {
        key: 'contact_email',
        title: 'Emails',
        dataIndex: 'contact_email',
        sorter: (a, b) => (a.primary_email || '').localeCompare(b.primary_email || ''),
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={items} />
    },
    {
        key: 'contact_website',
        title: 'Websites',
        dataIndex: 'contact_website',
        sorter: (a, b) => (a.primary_email || '').localeCompare(b.primary_email || ''),
        ellipsis: { showTitle: false },
        render: (items) => <TooltipInfo items={items} render={(value) => <a href={value}>{value}</a>}/>
    },
    {
        key: 'agent_name',
        title: 'Sales agent',
        dataIndex: 'agent_name',
        sorter: (a, b) => (a.provider || '').localeCompare(b.provider || ''),
        ellipsis: { showTitle: false },
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
        render: (items) => <TagInfo {...{ items }} />
    },
]