import { Button, Checkbox, Col, Input, Row, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Reducer, serializeQuery } from '../../../_helpers';
import './BaseTable.css';

function BaseTable(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectSlice, setState, selectRow, getPage } = Reducer();
    const { query, visible, loading, selectedKey, table, columns, records, temp } = useSelector(selectSlice);
    var { searchText, checked, selections, count, page, pageSize } = table || {};
    const { tableProps, display, selectable } = props
    const data = props.data || records
    /**
     * 
     * @param {Number} page 
     * @param {Number} pageSize 
     */
    const changePage = (page, pageSize) => {
        localStorage.setItem(`${query.document}/pageSize`, pageSize)
        var updated_query = { ...query }
        if (updated_query.id != null) {
            delete updated_query.id
            navigate(`?${serializeQuery(updated_query)}`)
        }
        dispatch(setState({ query: updated_query, table: { ...table, page: page, pageSize } }))
        if (records.length <= pageSize) {
            dispatch(getPage({
                document: query.document, data: { ...updated_query, limit: pageSize, skip: pageSize * (page - 1) }
            }))
        }
    }
    /**
     * 
     * @param {String} key key of column
     * @returns 
     */
    const getSearchText = (key) => {
        return searchText ? searchText[key] : null
    }
    /**
     * 
     * @param {String} key key of column
     * @param {String} text search value
     */
    const setSearchText = (key, text) => {
        var tempSearchText = {};
        if (searchText != null) tempSearchText = { ...searchText };
        tempSearchText[key] = text;
        dispatch(setState({ table: { ...table, searchText: tempSearchText } }));
    };
    /**
     * 
     * @param {String} key key of column
     * @param {Array<String>} arrayKeys key of column with array value
     * @returns 
     */
    const getOptions = (key, arrayKeys = []) => {
        var colOptions = [].concat(...(data || []).map(x => {
            return Array.isArray(x[key]) ? x[key] : [x[key]]
        })).filter((opt, idx) => ![null, undefined, ''].includes(opt));

        if (arrayKeys?.includes(key)) {
            colOptions = [].concat(...colOptions).filter((opt, idx) => ![null, undefined, ''].includes(opt));
        }
        colOptions = [...new Set(colOptions)].sort((a, b) => (a > b ? 1 : -1))
            .map(opt => ({ label: opt?.toString(), value: opt }));
        colOptions.unshift({ label: '(Blanks)', value: '' });
        var text = getSearchText(key);
        if (![null, undefined, ''].includes(text)) {
            colOptions = colOptions.filter(opt => opt?.label?.toString().toLowerCase().includes(text?.toLowerCase()));
        }
        return colOptions;
    }
    /**
     * 
     * @param {String} key key of column
     * @returns {Array<String|Number>}
     */
    const getChecked = (key) => {
        return checked ? (checked[key] || []) : [];
    }
    /**
     * 
     * @param {String} key key of column
     * @param {Array<String>} selection array of checked values
     */
    const setChecked = (key, selection) => {
        var tempChecked = {};
        if (checked != null) tempChecked = { ...checked };
        tempChecked[key] = selection;
        dispatch(setState({ table: { ...table, checked: tempChecked } }));
    }
    /**
     * 
     * @param {String} key 
     * @param {String} searchKeys
     * @param {Array<String>} arrayKeys 
     * @returns 
     */
    const getSearchProps = (key, searchKeys = [], arrayKeys = []) => {
        if (!searchKeys.includes(key)) return {}
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8, width: 300 }}>
                    <Input
                        allowClear
                        ref={node => {
                            // this.searchInput = node;
                        }}
                        placeholder={`Search ${key}`}
                        value={getSearchText(key)}
                        onChange={e => setSearchText(key, e.target.value)}
                        style={{ marginBottom: 8, width: 250 }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button type="link" size="small"
                            onClick={() => { setChecked(key, getOptions(key, arrayKeys).map(opt => opt.value)) }}
                        >
                            Select all
                        </Button>
                        <Button type="link" size="small"
                            onClick={() => { setChecked(key, []) }}
                        >
                            Deselect all
                        </Button>
                    </div>
                    <Row gutter={[8, 8]} style={{ maxWidth: 300, marginTop: '20px', maxHeight: '50vh', overflowY: 'scroll' }}>
                        {
                            getOptions(key, arrayKeys).map(opt => <Col key={opt.value} span={24}>
                                <Checkbox value={opt.value} checked={getChecked(key).includes(opt.value)}
                                    onChange={(e) => {
                                        var selected;
                                        if (e.target.checked === true) {
                                            selected = [...new Set([...getChecked(key), opt.value])];
                                        } else {
                                            selected = getChecked(key).filter(x => x !== opt.value);
                                        }
                                        setChecked(key, selected);
                                    }}
                                >
                                    {opt.label}
                                </Checkbox>
                            </Col>
                            )
                        }
                    </Row>
                </div>
            ),
            filterMultiple: true,
            filteredValue: getChecked(key),
            onFilter: (value, record) => {
                var valid = true
                if (value === '') {
                    if (arrayKeys?.includes(key)) {
                        valid = record[key]?.length === 0 || record[key]?.findIndex(r => ['', null, undefined].includes(r)) > -1
                    } else {
                        valid = ['', null, undefined].includes(record[key])
                    }
                }
                else if (arrayKeys?.includes(key) || Array.isArray(record[key])) {
                    valid = getChecked(key).find(x => !record[key].includes(x)) == null
                } else {
                    valid = record[key]?.toString().toLowerCase() === value?.toString().toLowerCase()
                }
                return valid
            },
        }
    };
    var { searchKeys, arrayKeys, expandable, rowSelection, pagination, columnFilter } = tableProps || {};
    var selectedCols = tableProps.columns?.map(x => x.key) || [];

    if (columns?.selected?.length > 1 && columnFilter !== false) selectedCols = columns?.selected;
    selectedCols = display?.columns ? selectedCols.filter(x => display.columns.includes(x)) : selectedCols;
    const tableCols = tableProps.columns;
    const displayCols = (
        display?.columns ? tableCols?.filter(x => display?.columns.includes(x.key)) : tableCols || []
    ).filter(
        x => x.title === '' || x.key === null || selectedCols.includes(x.key)
    ).map(x => ({
        ...x,
        ...getSearchProps(
            x.key,
            searchKeys,
            arrayKeys
        )
    })) || []
    const isFiltered = Object.keys(checked || {}).find(k => (checked || {})[k]?.length > 0) != null

    return (
        <Table
            {...tableProps}
            loading={loading.page === true}
            size='small'
            dataSource={data}
            columns={displayCols}
            scroll={{
                x: false,
                y: false
            }}
            sticky
            pagination={{
                ...(!isFiltered && records.length <= pageSize) ? { total: count } : {},
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${new Intl.NumberFormat('en-US', { notation: 'compact' }).format(total)}`,
                current: page,
                pageSize: pageSize,
                pageSizeOptions: [20, 50, 100],
                onChange: changePage,
                position: ['topRight', 'none'],
                ...pagination
            }}
            rowSelection={
                (rowSelection === true || selectedCols.includes('selection')) ? {
                    selectedRowKeys: selections,
                    onChange: (selectedKeys, selectedRows) => {
                        dispatch(setState({ table: { ...table, selections: selectedKeys } }));
                    },
                    selections: true, // [Table.SELECTION_ALL, Table.SELECTION_NONE],
                    columnWidth: 40
                } : false
            }
            rowClassName={(record, index) => {
                var classes = []
                if (record.key === selectedKey) classes.push('selected-row')
                if (temp?.findIndex(x => x.key === record.key) > -1) classes.push('updated-row')
                return classes
            }}
            onRow={tableProps.rowSelectable !== false ? (record, rowIndex) => {
                return {
                    onClick: event => {
                        if (selectable !== false) dispatch(selectRow({ key: record.key }))
                    }, // click row
                    onDoubleClick: event => {
                        if (visible?.drawer && record.key === selectedKey) {
                            if (selectable !== false) dispatch(selectRow({ key: null }))
                        }
                    }, // double click row
                    onContextMenu: event => { }, // right button click row
                };
            } : null}
            expandable={expandable}
            onChange={(pagination, filters, sorter, extra) => {
            }}
        />
    )
}

export default BaseTable