import React from 'react';
import BaseTable from '../_base/BaseTable';
// import RecordActions from '../_shared/RecordActions'
import { useSelector } from 'react-redux';
import { Reducer } from '../../../_helpers';
import { pnlColumns } from './columns';

export function PnLTable(props) {
    const { selectSlice } = Reducer();
    const { query, records } = useSelector(selectSlice);
    // const { display } = props
    const tableProps = {
        columns: [
            ...pnlColumns,
            // {
            //     key: 'action',
            //     title: '',
            //     dataIndex: null,
            //     width: '2vw',
            //     render: (text, record, index) => <RecordActions
            //         title={record.period}
            //         display={{
            //             actions: display?.actions || [
            //                 'Pay salaries', 'Recalculate tax'
            //             ]
            //         }}
            //         record={record}
            //     />
            // }
        ],
        searchKeys: ['period', 'base_currency'],
        arrayKeys: [],
    }
    const data = records.map(x => ({ ...query, ...x }));

    return (
        <BaseTable
            {...props}
            tableProps={tableProps}
            data={data}
        />
    )
}
