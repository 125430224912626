import { createAsyncThunk } from "@reduxjs/toolkit";
import { Account } from "../_api";
import { serializeError } from "../_helpers";

/**
 * Send a search request
 * 
 * @param {String} document 
 * @param {String} id 
 */
export const search = createAsyncThunk(
    'account/search[loading]',
    async ({ keyword }, _) => await Account.search(keyword),
    { serializeError }
);
/**
 * Send a get current user request
 *
 * @param {Object} data
 */
export const getUser = createAsyncThunk(
    'account/getUser',
    async () => await Account.current(),
    { serializeError }
);
/**
 * Send a get user billing request
 *
 * @param {Number} skip
 */
export const billing = createAsyncThunk(
    'account/billing',
    async ({ skip }, _) => await Account.billing(skip),
    { serializeError }
);
/**
 * Send a get user notification request
 *
 * @param {number} skip
 * @param {number} limit
 * @returns {Promise<Array<Object>>}
 */
export const getNotifications = createAsyncThunk(
    'account/notifications',
    async ({ skip, limit }, _) => await Account.notifications(skip, limit),
    { serializeError }
);
/**
 * Send a save user data request
 *
 * @param {Object}  data
 * @returns {Promise<UserAuth>}
 */
export const saveProfile = createAsyncThunk(
    'account/save',
    async ({ data }, _) => await Account.save(data),
    { serializeError }
);
/**
 * Send a save user data request
 *
 * @param {Object}  data
 * @returns {Promise<UserAuth>}
 */
export const saveParams = createAsyncThunk(
    'account/save',
    async ({ data }, _) => await Account.saveParams(data),
    { serializeError }
);
/**
 * Send a read notification request
 *
 * @param {Object} data
 */
export const readNotification = createAsyncThunk(
    'account/notifications[loading]/read',
    async ({ mode, data }, _) => await Account.readNotification(mode, data),
    { serializeError }
);
/**
 * Send a bill for checkout request
 * 
 * @param {String} id Order record ID
 */
export const checkoutBill = createAsyncThunk(
    'account/checkout',
    async ({ id }, _) => await Account.checkoutBill(id),
    { serializeError }
)
/**
 * Send a bill by order IDs for checkout request
 * 
 * @param {Array<String>} data Order record IDs
 */
export const checkoutOrders = createAsyncThunk(
    'account/checkout',
    async ({ data }, _) => await Account.checkoutOrders(data),
    { serializeError }
)
/**
 * Send a bill by order IDs for checkout request
 * 
 * @param {Array<String>} data Order record IDs
 */
export const checkoutTopup = createAsyncThunk(
    'account/checkout',
    async ({ query, data }, _) => await Account.checkoutTopup(query, data),
    { serializeError }
)
