import requests from "./_base";

export const Account = {
    /**
     * Get current user
     *
     * @returns {Promise<UserAuth>}
     */
    current: () => requests.get('/api/account/profile'),
    /**
     * Get billing data
     *
     * @param {number} skip
     * @returns {Promise<Object>}
     */
    billing: (skip) => requests.get(`/api/account/billing-data`, { skip }),
    /**
     * Get user notifications
     *
     * @param {number} skip
     * @param {number} limit
     * @returns {Promise<Array<Object>>}
     */
    notifications: (skip, limit) => requests.get(`/api/account/notification`, { skip, limit }),
    /**
     * Update user
     *
     * @param {Object}  data
     * @returns {Promise<UserAuth>}
     */
    save: (data) => requests.patch('/api/account/profile', {}, data),
    /**
     * 
     * @param {Object}  data 
     * @returns 
     */
    saveParams: (data) => requests.patch('/api/account/role-param', {}, data),
    /**
     * Mark notification as READ
     *
     * @param {'partial'|'all'} mode
     * @param {Array<String>} data Notification IDs
     * @returns {Promise<Object>}
     */
    readNotification: (mode, data) => requests.patch('/api/account/notification', { mode }, data),
    /**
     * Keyword search for records
     *
     * @param {String} keyword
     * @returns {Promise<Object>}
     */
    search: (keyword) => requests.get('/api/account/search', { keyword }),
    /**
     * 
     * @param {String} id Order record ID
     * @returns 
     */
    checkoutBill: (id) => requests.post(`/api/client/checkout/invoice/${id}`),
    /**
     * 
     * @param {Array<String>} data Order record IDs
     * @returns 
     */
    checkoutOrders: (data) => requests.post(`/api/client/checkout/order`, {}, data),
    /**
     * 
     * @param {Array<String>} data Order record IDs
     * @returns 
     */
    checkoutTopup: (query, data) => requests.post(`/api/client/checkout/topup`, query, data),
    /**
     * 
     * @param {String} id Paypal order ID
     * @returns 
     */
    verifyPaypal: (id) => requests.post(`/api/client/checkout/paypal/${id}`),
}

