import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api, serializeError } from "../_helpers";

/**
 * @param {String} document 
 * @param {String} id 
 */
export const search = createAsyncThunk(
    'dashboard/search',
    async ({ document, id }, _) => await Api().search(document, id),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Array<String>} tags 
 */
export const searchTags = createAsyncThunk(
    'dashboard/searchTags',
    async ({ document, tags }, _) => await Api().searchTags(document, tags),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Object} query 
 * @param {Object} data 
 */
export const create = createAsyncThunk(
    'dashboard/create',
    async ({ document, query, data }, _) => await Api().create(document, query, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Object} query 
 * @param {Object} data 
 */
export const update = createAsyncThunk(
    'dashboard/update',
    async ({ document, query, data }, _) => await Api().update(document, query, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Object} data 
 */
export const updateMany = createAsyncThunk(
    'dashboard/update',
    async ({ document, data }, _) => await Api().updateMany(document, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {String} status 
 * @param {Array<String>} data  record id(s)
 */
export const updateStatus = createAsyncThunk(
    'dashboard/update',
    async ({ document, status, data }, _) => await Api().updateStatus(document, status, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Array<String>} data record id(s)
 */
export const _export = createAsyncThunk(
    'dashboard/export',
    async ({ document, data }, _) => await Api().export(document, data),
    { serializeError }
);
/**
 * @param {String} document 
 * @param {Array<String>} data record id(s)
 */
export const _delete = createAsyncThunk(
    'dashboard/delete',
    async ({ document, data }, _) => await Api().delete(document, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
 */
export const updateUserRoles = createAsyncThunk(
    'dashboard/update',
    async ({ id, data }, _) => await Api().updateUserRoles(id, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} data 
 */
export const updateUserContracts = createAsyncThunk(
    'dashboard/update',
    async ({ id, data }, _) => await Api().updateUserContracts(id, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {('clients'|'editors'|'qcs')} field
 * @param {Object} data 
 */
export const updateContractParty = createAsyncThunk(
    'dashboard/update',
    async ({ id, field, data }, _) => await Api().updateContractParty(id, field, data),
    { serializeError }
);
/**
 * @param {String} id 
 */
export const makeContractDefault = createAsyncThunk(
    'dashboard/update',
    async ({ id }, _) => await Api().makeContractDefault(id),
    { serializeError }
);
/**
 * @param {*} id 
 * @param {Object} [query] 
 * @param {String} [query.policy_group]
 * @param {('clients'|'editors'|'qcs'|'employees'|'agents')} [query.field]
 * @param {Object} data 
 */
export const updatePolicyObject = createAsyncThunk(
    'dashboard/update',
    async ({ id, policy_group, field, data }, _) => await Api().updatePolicyObject(id, { policy_group, field }, data),
    { serializeError }
);
/**
 * @param {Boolean} unset 
 * @param {Object} query 
 * @param {Array<String>} data record ids
 */
export const updateOrderHistory = createAsyncThunk(
    'dashboard/update',
    async ({ unset, query, data }, _) => await Api().updateOrderHistory(unset, query, data),
    { serializeError }
);
/**
 * @param {('create'|'add'|'remove')} action 
 * @param {Object} data 
 */
export const updateOrderBilling = createAsyncThunk(
    'dashboard/update',
    async ({ action, data }, _) => await Api().updateOrderBilling(action, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const addRequest = createAsyncThunk(
    'dashboard/update',
    async ({ id, query, data }, _) => await Api().addRequest(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const updateRequest = createAsyncThunk(
    'dashboard/update',
    async ({ id, query, data }, _) => await Api().updateRequest(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const addRequestComment = createAsyncThunk(
    'dashboard/update',
    async ({ id, query, data }, _) => await Api().addRequestComment(id, query, data),
    { serializeError }
);
/**
 * @param {String} id 
 * @param {Object} query 
 * @param {Object} data 
 */
export const modifyRequestComment = createAsyncThunk(
    'dashboard/update',
    async ({ id, query, data }, _) => await Api().modifyRequestComment(id, query, data),
    { serializeError }
);
/**
 * @param {('pending'|'working'|'delivered'|'canceled')} status 
 * @param {Object} query 
 * @param {Object} data 
 */
export const setRequestStatus = createAsyncThunk(
    'dashboard/update',
    async ({ status, query, data }, _) => await Api().setRequestStatus(status, query, data),
    { serializeError }
);
/**
 * @param {String} key s3 file key
 */
export const importTimebook = createAsyncThunk(
    'dashboard/importTimebook',
    async ({ key }, _) => await Api().importTimebook(key),
    { serializeError }
);
/**
 * @param {('add'|'remove')} action 
 * @param {Object} query 
 */
export const updateTimebookBillRefs = createAsyncThunk(
    'dashboard/update',
    async ({ action, query, data }, _) => await Api().updateTimebookBillRefs(action, query, data),
    { serializeError }
);
/** 
 * Client API
 * @param {Object} data 
 */
export const getClientToScan = createAsyncThunk(
    'dashboard/create',
    async ({ data }, _) => await Api().getClientToScan(),
    { serializeError }
);
/**
 * @param {String} key 
 */
export const importClients = createAsyncThunk(
    'dashboard/importClients',
    async ({ key }, _) => await Api().importClients(key),
    { serializeError }
);
/** 
 * Client API
 * @param {Object} data 
 */
export const createClientUser = createAsyncThunk(
    'dashboard/update',
    async ({ data }, _) => await Api().createClientUser(data),
    { serializeError }
);
/** 
 * Campaign API
 * @param {Object} data 
 */
export const scheduleCampaign = createAsyncThunk(
    'dashboard/update',
    async ({ id, send_at }, _) => await Api().scheduleCampaign(id, send_at),
    { serializeError }
);
/** 
 * Campaign API
 * @param {Object} data 
 */
export const pauseCampaignSchedule = createAsyncThunk(
    'dashboard/update',
    async ({ id }, _) => await Api().pauseCampaignSchedule(id),
    { serializeError }
);
/** 
 * Campaign API
 * @param {Object} data 
 */
export const cancelCampaign = createAsyncThunk(
    'dashboard/delete',
    async ({ id }, _) => await Api().cancelCampaign(id),
    { serializeError }
);
/**
 * @param {Object} data 
 */
export const recalculateBills = createAsyncThunk(
    'dashboard/update',
    async ({ data }, _) => await Api().recalculateBills(data),
    { serializeError }
);
/**
 * @param {('single'|'separate')} type 
 * @param {Object} data 
 */
export const makeBillsPayment = createAsyncThunk(
    'dashboard/update',
    async ({ type, data }, _) => await Api().makeBillsPayment(type, data),
    { serializeError }
);
/**
 * @param {String} document 'api_integration' | 'role_parameters' | 'logs' | 'user' | 
 * 'product' | 'contract'| 'policy' | 'order' | 'timebook' | 'billing' | 'transaction'
 * @param {Object} data 
 */
export const dashboard = createAsyncThunk(
    'dashboard/data',
    async ({ document, data }, _) => await Api().dashboard(document, data),
    { serializeError }
);
/**
 * @param {String} document 'api_integration' | 'role_parameters' | 'logs' | 'user' | 
 * 'product' | 'contract'| 'policy' | 'order' | 'timebook' | 'billing' | 'transaction'
 * @param {Object} data 
 */
export const getPage = createAsyncThunk(
    'dashboard/page[loading]',
    async ({ document, data }, _) => await Api().dashboard(document, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
 * @returns 
 */
export const getStatistics = createAsyncThunk(
    'dashboard/statistics',
    async ({ name, data }, _) => await Api().statistics(name, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const completeEdits = createAsyncThunk(
    'dashboard/update',
    async ({ name, data }, _) => await Api().completeEdits(name, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const reviewEdits = createAsyncThunk(
    'dashboard/update',
    async ({ name, data }, _) => await Api().reviewEdits(name, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const takeQcs = createAsyncThunk(
    'dashboard/assignQcs',
    async ({ type, data }, _) => await Api().takeQcs(type, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const returnQcs = createAsyncThunk(
    'dashboard/assignQcs',
    async ({ type, data }, _) => await Api().returnQcs(type, data),
    { serializeError }
);
/**
 * @param {String} name 
 * @param {Object} data 
*/
export const completeQcs = createAsyncThunk(
    'dashboard/update',
    async ({ type, data }, _) => await Api().completeQcs(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const reviewQcs = createAsyncThunk(
    'dashboard/update',
    async ({ type, data }, _) => await Api().reviewQcs(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const completeRequests = createAsyncThunk(
    'dashboard/update',
    async ({ type, data }, _) => await Api().completeRequests(type, data),
    { serializeError }
);
/**
 * @param {String} type 
 * @param {Object} data 
*/
export const reviewRequests = createAsyncThunk(
    'dashboard/update',
    async ({ type, data }, _) => await Api().reviewRequests(type, data),
    { serializeError }
);
