import {
  CalculatorOutlined, PauseCircleOutlined, CheckSquareOutlined, CloseCircleOutlined, CloseSquareOutlined, CreditCardOutlined, DeleteOutlined,
  DoubleRightOutlined, EyeOutlined, LinkOutlined, LockOutlined, PlayCircleOutlined, RestOutlined,
  RollbackOutlined, SafetyCertificateOutlined, SendOutlined, UndoOutlined, UnlockOutlined, WarningOutlined
} from '@ant-design/icons'
import { message } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../app/config'
import { Api, Reducer, Status } from '../../../_helpers'
import { ListActions } from '../../display'

function RecordActions(props) {
  const dispatch = useDispatch()
  const {
    selectSlice, setState, selectRow, updateStatus,
    updateOrderHistory, recalculateBills,
    completeEdits, reviewEdits,
    completeQcs, reviewQcs,
    completeRequests, reviewRequests,
    getBillsForTax,
    pauseCampaignSchedule, cancelCampaign,
    _delete
  } = Reducer()
  const { query } = useSelector(selectSlice)
  const { document } = query || {}
  const { title, display, record } = props
  const status = record.status?.toLowerCase()
  const actions = [
    // Info
    {
      group: 'Info',
      title: 'Info'
    },
    {
      title: 'View and edit',
      icon: <EyeOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(selectRow({ key: record.key }))
      }
    },
    {
      title: 'Go to Filebox',
      icon: <DoubleRightOutlined />,
      disabled: false,
      onClick: () => {
        window.open(`storage?id=${record.id}`)
      }
    },
    // Record status
    {
      group: 'Status',
      title: 'Status'
    },
    {
      title: 'Activate',
      icon: <SafetyCertificateOutlined />,
      disabled: ['active'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'active', data: record.id }))
      }
    },
    {
      title: 'Deactivate',
      icon: <RestOutlined />,
      disabled: ['inactive'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'inactive', data: record.id }))
      }
    },
    {
      title: 'Lock',
      icon: <LockOutlined />,
      disabled: ['locked'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'locked', data: record.id }))
      }
    },
    {
      title: 'Unlock',
      icon: <UnlockOutlined />,
      disabled: !['locked'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'unlock', data: record.id }))
      }
    },
    {
      title: 'Delete',
      icon: <DeleteOutlined />,
      disabled: ['deleted', 'done'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'deleted', data: record.id }))
      }
    },
    {
      title: 'Restore',
      icon: <RestOutlined />,
      disabled: !['deleted'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'restore', data: record.id }))
      }
    },
    {
      title: 'Remove',
      icon: <DeleteOutlined />,
      disabled: ['paid', 'success', 'failure'].findIndex(s => status === s) > 0,
      onClick: () => {
        dispatch(_delete({ document, data: record.id }))
      }
    },
    // Policy
    {
      title: 'Apply to records',
      icon: <PlayCircleOutlined />,
      disabled: status !== "active",
      onClick: async () => {
        dispatch.setState({ status: Status.LOADING })
        await Api().applyPolicies([record.id]).then(
          res => {
            const { order, timebook } = res.updated_count
            message.info(`Successfully apply policy ${record.name} to ${order} orders and ${timebook} timebooks`, 10)
          }
        ).catch((e) => {
          message.error(e.detail)
        })
        dispatch(setState({ status: Status.SUCCESS }))
      }
    },
    // Order
    {
      title: 'Deliver',
      icon: <SendOutlined />,
      disabled: status === 'deleted' || record?.product_list?.findIndex(
        x => !['delivered', 'canceled'].includes(x.last_action) && x.amount != null
      ) < 0,
      onClick: () => {
        dispatch(
          updateOrderHistory({
            unset: false, query: { type: 'job', action: 'delivered' }, data: [record.id]
          })
        )
      }
    },
    {
      title: 'Cancel',
      icon: <CloseCircleOutlined />,
      disabled: status === 'deleted' || record?.product_list?.findIndex(
        x => !['delivered', 'canceled'].includes(x.last_action) && x.amount != null
      ) < 0,
      onClick: () => {
        dispatch(
          updateOrderHistory({
            unset: false, query: { type: 'job', action: 'canceled' }, data: [record.id]
          })
        )
      }
    },
    {
      title: 'Undone',
      icon: <UndoOutlined />,
      disabled: status === 'deleted' || record?.product_list?.findIndex(x => !['delivered', 'canceled'].includes(x.last_action)) > -1,
      onClick: () => {
        dispatch(
          updateOrderHistory({
            unset: true, query: { type: 'job', action: 'delivered' }, data: [record.id]
          })
        )
      }
    },
    {
      group: 'Products',
      title: 'Products'
    },
    {
      title: 'Complete editings',
      icon: <SendOutlined />,
      disabled: status === 'deleted' || ['new'].findIndex(x => record.product_status === x) < 0,
      onClick: () => {
        dispatch(completeEdits({ data: [record.id], type: 'job' }))
      }
    },
    {
      title: 'Review editings',
      icon: <CloseCircleOutlined />,
      disabled: status === 'deleted' || ['edited'].findIndex(x => record.product_status === x) < 0,
      onClick: () => {
        dispatch(reviewEdits({ data: [record.id], type: 'job' }))

      }
    },
    {
      title: 'Complete QCs',
      icon: <SendOutlined />,
      disabled: status === 'deleted' || ['new', 'edited'].findIndex(x => record.product_status === x) < 0,
      onClick: () => {
        dispatch(completeQcs({ data: [record.id], type: 'job' }))
      }
    },
    {
      title: 'Review QCs',
      icon: <CloseCircleOutlined />,
      disabled: status === 'deleted' || ['qc'].findIndex(x => record.product_status === x) < 0,
      onClick: () => {
        dispatch(reviewQcs({ data: [record.id], type: 'job' }))
      }
    },
    {
      group: 'Requests',
      title: 'Requests'
    },
    {
      title: 'Complete post requests',
      icon: <SendOutlined />,
      disabled: status === 'deleted' || ['working'].findIndex(x => record.request_status === x) < 0,
      onClick: () => {
        dispatch(completeRequests({ data: [record.id], type: 'job' }))
      }
    },
    {
      title: 'Review post requests',
      icon: <CloseCircleOutlined />,
      disabled: status === 'deleted' || ['completed'].findIndex(x => record.request_status === x) < 0,
      onClick: () => {
        dispatch(reviewRequests({ data: [record.id], type: 'job' }))
      }
    },
    // Billing
    {
      title: 'Recalculate',
      icon: <CalculatorOutlined />,
      disabled: !['draft', 'sent'].includes(status),
      onClick: () => {
        dispatch(recalculateBills({ data: [record.id] }))
      }
    },
    {
      title: 'Return to draft',
      icon: <RollbackOutlined />,
      disabled: !['sent'].includes(status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'DRAFT', data: [record.id] }))
      }
    },
    {
      title: 'Sent to user',
      icon: <SendOutlined />,
      disabled: ['draft'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'SENT', data: record.id }))
      }
    },
    {
      title: 'Move to bad debt',
      icon: <WarningOutlined />,
      disabled: ['draft', 'sent'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'BAD_DEBT', data: record.id }))
      }
    },
    {
      title: 'Mark as loss',
      icon: <CloseSquareOutlined />,
      disabled: ['draft', 'sent', 'bad_debt'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'LOSS', data: record.id }))
      }
    },
    // Transaction
    {
      title: 'Sent to processing',
      icon: <SendOutlined />,
      disabled: ['draft', 'success', 'failure'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'processing', data: [record.id] }))
      }
    },
    {
      title: 'Mark as success',
      icon: <CheckSquareOutlined />,
      disabled: ['draft', 'processing', 'failure'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'success', data: [record.id] }))
      }
    },
    {
      title: 'Mark as failure',
      icon: <CloseSquareOutlined />,
      disabled: ['draft', 'processing', 'success'].findIndex(s => status === s) < 0,
      onClick: () => {
        dispatch(updateStatus({ document, status: 'failure', data: record.id }))
      }
    },
    // Client
    {
      title: 'Mark as invalid',
      icon: <DeleteOutlined />,
      disabled: record.status === 'invalid',
      onClick: () => {
        dispatch(updateStatus({ document, status: 'invalid', data: record.id }))
      }
    },
    {
      title: 'Mark as declined',
      icon: <CloseSquareOutlined />,
      disabled: record.status === 'declined',
      onClick: () => {
        dispatch(updateStatus({ document, status: 'declined', data: record.id }))
      }
    },
    {
      title: 'Restore to set',
      icon: <RestOutlined />,
      disabled: !['invalid', 'declined'].includes(record.status),
      onClick: () => {
        dispatch(updateStatus({ document, status: 'set', data: record.id }))
      }
    },
    {
      title: 'Get sign-up link',
      icon: <LinkOutlined />,
      disabled: ['invalid', 'declined'].includes(record.status),
      onClick: () => {
        navigator.clipboard.writeText(`${config.appRoot}/login?id=${record.id}`)
        message.info(`Sign-up link for ${record.name} copied to clipboard`, 10)
      }
    },
    // Campaign
    {
      title: 'Set schedule',
      icon: <PauseCircleOutlined />,
      disabled: status !== 'draft',
      onClick: () => {
        dispatch(setState({ forms: { schedule: { visible: true, campaignId: record.id } } }))
      }
    },
    {
      title: 'Pause schedule',
      icon: <PauseCircleOutlined />,
      disabled: status !== 'scheduled',
      onClick: () => {
        dispatch(pauseCampaignSchedule({ id: record.id }))
      }
    },
    {
      title: 'Cancel campaign',
      icon: <DeleteOutlined />,
      disabled: status !== 'draft',
      onClick: () => {
        dispatch(cancelCampaign({ id: record.id }))
      }
    },
    // Tax
    {
      title: 'Pay salaries',
      icon: <CreditCardOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(setState({
          forms: { payment: { visible: true, taxId: record.id } }
        }))
      }
    },
    {
      title: 'Recalculate tax',
      icon: <CalculatorOutlined />,
      disabled: false,
      onClick: () => {
        dispatch(setState({
          forms: {
            settings: {
              visible: true, taxId: record.id, period: record.period,
              fullAudit: [], partialAudit: [], fullInaudit: [], insuranceBase: [{ employee: null }]
            }
          }
        }))
        dispatch(getBillsForTax({ data: [record.period] }))
      }
    }
  ]
  const displayActions = display?.actions ?
    actions.filter(x => display?.actions.includes(x.title)) :
    actions

  return (
    <ListActions id='table-actions' title={title} actions={displayActions} />
  )
}

export default RecordActions