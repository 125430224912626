import requests from "./_base";

export const QC = {
    /**
     * Common API
     * @param {String} document 
     * @param {String} id 
     * @returns {Object}
     */
    search: (document, id) => requests.get(`/api/qc/records/${document}`, { id }),
    /**
     * Report API
     * @param {String} name 
     * @param {Object} data 
     * @returns 
     */
    statistics: (name, data) => requests.post(`/api/qc/statistics/${name}`, {}, data),
    /**
     * 
     * @param {('api_integration'|'role_parameters'|'logs'|'user'|'product'|'contract'|
     * 'policy'|'order'|'timebook'|'billing'|'transaction')} document 
     * @param {Object} data 
     * @returns 
     */
    dashboard: (document, data) => requests.post(`/api/qc/dashboard/${document}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    completeEdits: (type, data) => requests.patch(`/api/editor/product/complete/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    reviewEdits: (type, data) => requests.patch(`/api/editor/product/review/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    takeQcs: (type, data) => requests.post(`/api/qc/product/take/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    returnQcs: (type, data) => requests.post(`/api/qc/product/return/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    completeQcs: (type, data) => requests.patch(`/api/qc/product/complete/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    reviewQcs: (type, data) => requests.patch(`/api/qc/product/review/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    completeRequests: (type, data) => requests.patch(`/api/editor/request/complete/${type}`, {}, data),
    /**
     * 
     * @param {'job'|'product'} type 
     * @param {Array<String} data 
     * @returns 
     */
    reviewRequests: (type, data) => requests.patch(`/api/editor/request/review/${type}`, {}, data),
}