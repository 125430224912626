import { Tag } from 'antd'
import React from 'react'
import { statusTags } from '../../_styles/tag.styles'


function TagInfo({ items }) {
    if (Array.isArray(items)) {
        return items.map(it => it.toLowerCase()).map(
            it => items ? <Tag color={statusTags.find(s => s.values.includes(it))?.color || 'default'} key={it}>
                {it}
            </Tag> : null) 

    } else {
        const item = String(items).toLowerCase()
        return items ? <Tag color={statusTags.find(s => s.values.includes(item))?.color || 'default'} key={item}>
            {item}
        </Tag> : null
    }
}

export default TagInfo