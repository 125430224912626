import requests from "./_base";

export const Client = {
    /**
     * Common API
     * @param {String} document 
     * @param {String} id 
     * @returns {Object}
     */
    search: (document, id) => requests.get(`/api/client/${document}`, { id }),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    create: (document, query, data) => requests.post(`/api/client/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    update: (document, query, data) => requests.patch(`/api/client/${document}`, query, data),
    /**
     * Order API
     * @param {String} id Order record ID
     * @param {Object} query 
     * @returns 
     */
    activate: (id, query) => requests.patch(`/api/client/order/draft-to-active/${id}`, query),
    /**
     * Order API
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    addRequest: (id, query, data) => requests.post(`/api/client/order/request/${id}`, query, data),
    /**
     * 
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    updateRequest: (id, query, data) => requests.patch(`/api/client/order/request/${id}`, query, data),
    /**
     * Order API
     * @param {String} id 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    addRequestComment: (id, query, data) => requests.post(`/api/client/order/request-comment/${id}`, query, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Array<String>} data paths of the folders
     * @returns 
     */
    createFolders: (id, data) => requests.post(`/api/client/objects/folders/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {'Input'|'Output'} folder_type 
     * @param {Array<String>} data paths
     * @returns 
     */
    listObjects: (id, { folder_type }, data) => requests.post(`/api/client/objects/list/${id}`, { folder_type }, data),
    /**
     * S3 Objects API
     * @param {String} id  order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    deleteObjects: (id, data) => requests.del(`/api/client/objects/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Object} [query] 
     * @param {String} [query.source] s3 key
     * @param {String} [query.name]
     * @returns 
     */
    renameObject: (id, { source, name }) => requests.patch(`/api/client/objects/${id}`, { source, name }),
    /**
     * 
     * @param {('home'|'customer'|'editor_and_qc'|'order'|'timebook')} document 
     * @param {Object} data 
     * @returns 
     */
    dashboard: (document, data) => requests.post(`/api/client/dashboard/${document}`, {}, data),
    /**
     * Report API
     * @param {String} name 
     * @param {Object} data 
     * @returns 
     */
    statistics: (name, data) => requests.post(`/api/client/statistics`, {}, data),
    /**
     * Storage API
     * @param {String} id 
     * @returns 
     */
    storage: (id, data) => requests.post(`/api/client/storage`, { id }, data),
    /**
     * 
     * @param {String} key search key
     * @returns 
     */
    jobTree: (key) => requests.get(`/api/client/storage/job-tree`, { key }),
    /**
     * 
     * @param {String} id order ID
     * @returns 
     */
    job: (id) => requests.get(`/api/client/storage/job-info`, { id }),
    /**
     * 
     * @param {String} id order ID
     * @param {String} path s3 path
     * @returns 
     */
    folder: (id, path) => requests.get(`/api/client/storage/job-folder`, { id, path }),
    /**
     * 
     * @param {String} id order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    postUpload: (id, data) => requests.post(`/api/client/storage/upload-finish/${id}`, {}, data),
}