import { config } from "../app/config";
import { serializeQuery, localToken, apiType } from "../_helpers";
import { WritableStream } from 'web-streams-polyfill/ponyfill';
import streamSaver from 'streamsaver';

const { apiRoot } = config

/**
 * API client
 *
 * @param {String} url The endpoint
 * @param {Object} body The request's body
 * @param {('GET'|'DELETE'|'PUT'|'POST')} [method='GET'] The request's method
 *
 * @throws {@link ApiError API Error}
 *
 * @returns {Promise<Object>} API response's body
 */
const agent = async (url, body, method = 'GET') => {
    const headers = new Headers();

    if (body) {
        headers.set('Content-Type', 'application/json');
    }
    if (localToken()) {
        headers.set('Authorization', `Bearer ${localToken()}`);
    }
    const response = await fetch(`${apiRoot}${url}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
    });
    let result;
    try {
        result = await response.json();
    } catch (error) {
        result = { errors: { [response.status]: [response.statusText] } };
    }

    if (!response.ok) {
        const errors = { [response.status]: response.statusText + ' : ' + JSON.stringify(result.detail) };
        throw errors;
    }

    return result;
};

const requests = {
    /**
     * Send a DELETE request
     *
     * @param {String} url The endpoint
     * @returns {Promise<Object>}
     */
    del: (url, query = {}, body) => {
        const isEmptyQuery = query == null || Object.keys(query).length === 0;

        return agent(isEmptyQuery ? url : `${url}?${serializeQuery(query)}`, body, 'DELETE')
    },
    /**
     * Send a GET request
     *
     * @param {String} url The endpoint
     * @param {Object} [query={}] URL parameters
     * @param {Number} [query.limit=10]
     * @param {Number} [query.page]
     * @param {String} [query.author]
     * @param {String} [query.tag]
     * @param {String} [query.favorited]
     * @returns {Promise<Object>}
     */
    get: (url, query = {}) => {
        const apiQuery = { ...(query || {}), api_type: apiType() }
        return agent(`${url}?${serializeQuery(apiQuery)}`);
    },
    /**
     * Send a PUT request
     *
     * @param {String} url The endpoint
     * @param {Record<string, unknown>} body The request's body
     * @returns {Promise<Object>}
     */
    put: (url, body) => agent(url, body, 'PUT'),
    /**
     * Send a PATCH request
     *
     * @param {String} url The endpoint
     * @param {Record<string, unknown>} body The request's body
     * @returns {Promise<Object>}
     */
    patch: (url, query = {}, body) => {
        const apiQuery = { ...(query || {}), api_type: apiType() }
        return agent(`${url}?${serializeQuery(apiQuery)}`, body, 'PATCH');
    },
    /**
     * Send a POST request
     *
     * @param {String} url The endpoint
     * @param {Record<string, unknown>} body The request's body
     * @returns {Promise<Object>}
     */
    post: (url, query = {}, body = undefined) => {
        const apiQuery = { ...(query || {}), api_type: apiType() }
        return agent(`${url}?${serializeQuery(apiQuery)}`, body, 'POST');
    },
    /**
     * 
     * @param {String} url 
     * @param {Record<string, unknown>} query 
     * @param {Record<string, unknown>} body 
     * @returns {Promise<Object>}
     */
    streamFile: (url, query = {}, body = undefined) => {
        const headers = new Headers();

        if (body) {
            headers.set('Content-Type', 'application/json');
        }
        if (localToken()) {
            headers.set('Authorization', `Bearer ${localToken()}`);
        }
        fetch(`${apiRoot}${url}`, {
            method: 'POST',
            headers,
            body: body ? JSON.stringify(body) : undefined,
        }).then(response => {
                let contentDisposition = response.headers.get('content-disposition');
                const items = contentDisposition.split(';')
                const fNameItem = items.find(x => x.includes('filename='));
                const fileName = fNameItem.substring(fNameItem.lastIndexOf('=') + 1);
                const fSizeItem = items.find(x => x.includes('size='));
                const fileSize = fSizeItem.substring(fSizeItem.lastIndexOf('=') + 1);

                // These code section is adapted from an example of the StreamSaver.js
                // https://jimmywarting.github.io/StreamSaver.js/examples/fetch.html

                // If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
                if (!window.WritableStream) {
                    streamSaver.WritableStream = WritableStream;
                    window.WritableStream = WritableStream;
                }

                const fileStream = streamSaver.createWriteStream(fileName, { size: parseInt(fileSize) });
                const readableStream = response.body;

                // More optimized
                if (readableStream.pipeTo) {
                    return readableStream.pipeTo(fileStream);
                }

                window.writer = fileStream.getWriter();

                const reader = response.body.getReader();
                const pump = () => reader.read()
                    .then(res => res.done
                        ? window.writer.close()
                        : window.writer.write(res.value).then(pump));

                pump();
            }).catch(error => {
                console.log(error);
            });
    }
};

export default requests