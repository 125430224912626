import { apiType } from "../_helpers";
import requests from "./_base";

export const PM = {
    /**
     * Common API
     * @param {String} document 
     * @param {String} id 
     * @returns {Object}
     */
    search: (document, id) => requests.get(`/api/pm/records/${document}`, { id }),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} tags 
     * @returns 
     */
    searchTags: (document, data) => requests.post(`/api/pm/records/tags/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    create: (document, query, data) => requests.post(`/api/pm/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} query 
     * @param {Object} data 
     * @returns 
     */
    update: (document, query, data) => requests.patch(`/api/pm/records/${document}`, query, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Object} data 
     * @returns 
     */
    updateMany: (document, data) => requests.patch(`/api/pm/records/bulk/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {String} status 
     * @param {Array<String>} data  record id(s)
     * @returns 
     */
    updateStatus: (document, status, data) => requests.patch(`/api/pm/records/status/${document}`, { status }, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} data record id(s)
     * @returns 
     */
    export: (document, data) => requests.post(`/api/pm/records/export/${document}`, {}, data),
    /**
     * Common API
     * @param {String} document 
     * @param {Array<String>} data record id(s)
     * @returns 
     */
    delete: (document, data) => requests.del(`/api/pm/records/${document}`, {}, data),
    /**
     * User API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    updateUserRoles: (id, data) => requests.patch(`/api/pm/user/roles/${id}`, {}, data),
    /**
     * User API
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    updateUserContracts: (id, data) => requests.patch(`/api/pm/user/contracts/${id}`, {}, data),
    /**
     * Contract API
     * @param {String} id 
     * @param {('clients'|'editors'|'qcs')} field
     * @param {Object} data 
     * @returns 
     */
    updateContractParty: (id, field, data) => requests.patch(`/api/pm/contract/party/${id}`, { field }, data),
    /**
     * Timebook API
     * @param {String} key s3 file key
     * @returns 
     */
    importTimebook: (key) => requests.get(`/api/pm/timebook/import`, { key }),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Array<String>} data paths of the folders
     * @returns 
     */
    createFolders: (id, data) => requests.post(`/api/pm/objects/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id  order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    deleteObjects: (id, data) => requests.del(`/api/pm/objects/${id}`, {}, data),
    /**
     * S3 Objects API
     * @param {String} id order ID
     * @param {Object} [query] 
     * @param {String} [query.source] s3 key
     * @param {String} [query.name]
     * @returns 
     */
    renameObject: (id, { source, name }) => requests.patch(`/api/pm/objects/${id}`, { source, name }),
    /**
     * 
     * @param {('api_integration'|'role_parameters'|'logs'|'user'|'product'|'contract'|
     * 'policy'|'order'|'timebook'|'billing'|'transaction')} document 
     * @param {Object} data 
     * @returns 
     */
    dashboard: (document, data) => requests.post(`/api/pm/dashboard/${document}`, {}, data),
    /**
     * Report API
     * @param {String} document 
     * @param {Object} data 
     * @returns 
     */
    reports: (document, data) => requests.post(`/api/pm/reports/${document}`, {}, data),
    /**
     * Report API
     * @param {String} name 
     * @param {Object} data 
     * @returns 
     */
    statistics: (name, data) => requests.post(`/api/pm/statistics/${apiType()}-${name}`, {}, data),
    /**
     * 
     * @param {String} id 
     * @param {Object} data 
     * @returns 
     */
    storage: (id, data) => requests.post(`/api/pm/storage`, { id }, data),
    /**
     * 
     * @param {String} key search key
     * @returns 
     */
    jobTree: (key) => requests.get(`/api/pm/storage/job-tree`, { key }),
    /**
     * 
     * @param {String} id order ID
     * @returns 
     */
    job: (id) => requests.get(`/api/pm/storage/job-info`, { id }),
    /**
     * 
     * @param {String} id order ID
     * @param {String} path s3 path
     * @returns 
     */
    folder: (id, path) => requests.get(`/api/pm/storage/job-folder`, { id, path }),
    /**
     * 
     * @param {String} id order ID
     * @param {Array<String>} data s3 keys
     * @returns 
     */
    postUpload: (id, data) => requests.post(`/api/pm/storage/upload-finish/${id}`, {}, data)
}
