import { Spin } from 'antd'
import React from 'react'

function LoadingScreen() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <div style={{ width: 25 }}>
                <Spin spinning={true} />
            </div>
        </div>
    )
}

export default LoadingScreen