import React from 'react'
import RecordActions from '../_shared/RecordActions'
import BaseTable from '../_base/BaseTable'
import { accountColumns } from './columns'

export function BillAccountTable(props) {
    const { display } = props
    const tableProps = {
        columns: [
            ...accountColumns,
            {
                key: 'action',
                title: '',
                dataIndex: null,
                width: '2vw',
                render: (text, record, index) => <RecordActions
                    title={record.job_ref}
                    display={{
                        actions: display?.actions || [
                            'View and edit', 'Activate', 'Deactivate'
                        ]
                    }}
                    record={record}
                />
            }
        ],
        searchKeys: [
            'date', 'client_name', 'job_ref', 'notes', 'products', 'amounts', 'prices',
            'editors', 'edit_rates', 'qcs', 'qc_rates', 'pr_editors', 'pr_qcs',
            'status', 'product_status', 'request_status', 'billing_status'],
        arrayKeys: ['products', 'amounts', 'prices', 'editors', 'edit_rates', 'qcs', 'qc_rates', 'pr_editors', 'pr_qcs'],
    }

    return (
        <BaseTable
            {...props}
            tableProps={tableProps}
        />
    )
}
