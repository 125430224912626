export const envVars = {
    dev: {
        appRoot: "http://127.0.0.1:3000",
        apiRoot: "http://127.0.0.1:8000",
        wsRoot: "ws://localhost:8000/ws",
        paypalClientID: "AUFHXen1T7EYzNCp2zQGotghpWAorznqn66BEzYKni1ZFwn1psYOQnnuKib9fLLVi9rjw0t2-bvQTsk-"
    },
    test: {
        appRoot: "https://testing.app.phtstudio.com",
        apiRoot: "https://testing.api.phtstudio.com",
        wsRoot: "wss://testing.api.phtstudio.com/ws",
        paypalClientID: "AUFHXen1T7EYzNCp2zQGotghpWAorznqn66BEzYKni1ZFwn1psYOQnnuKib9fLLVi9rjw0t2-bvQTsk-"
    },
    prod: {
        appRoot: "https://app.phtstudio.com",
        apiRoot: "https://api.phtstudio.com",
        wsRoot: "wss://api.phtstudio.com/ws",
        paypalClientID: "AV4iQogvD_uF5o7HBPZOn3BfNtNYhmOfqFZfOzCUCW66S91rg4WLVtz-lmMXlIIqazJz0CIQuH0i-Jz8"
    }
}

export const config = {
    debugMode: process.env.NODE_ENV === "development",
    ...envVars[process.env.REACT_APP_FRONTEND_ENV],
    rolePaths: [
        {
            role: "database admin",
            title: "DBA",
            loginPath: "/admin/dashboard",
            navBar: ['dashboard', 'filebox', 'reports']
        },
        {
            role: "production manager",
            title: "Production Manager",
            loginPath: "/pm/dashboard",
            navBar: ['dashboard', 'filebox', 'reports']
        },
        {
            role: "financial manager",
            title: "Financial Manager",
            loginPath: "/financial_manager/dashboard",
            navBar: ['dashboard', 'filebox', 'reports']
        },
        {
            role: "marketing manager",
            title: "Marketing Manager",
            loginPath: "/marketing_manager/dashboard",
            navBar: ['dashboard', 'filebox', 'reports']
        },
        {
            role: "customer service",
            title: "Customer Service",
            loginPath: "/cs/dashboard",
            navBar: ['dashboard', 'filebox']
        },
        {
            role: "quality control",
            title: "Quality Control",
            loginPath: "/qc/dashboard",
            navBar: ['dashboard']
        },
        {
            role: "editor",
            title: "Editor",
            loginPath: "/editor/dashboard",
            navBar: ['dashboard']
        },
        {
            role: "sales agent",
            title: "Sales Agent",
            loginPath: "/agent/dashboard",
            navBar: ['dashboard']
        },
        {
            role: "client",
            title: "Client",
            loginPath: "/client/storage",
            navBar: ['dashboard', 'filebox']
        },
        {
            role: "user",
            loginPath: "/welcome",
            navBar: []
        },
    ],
    reportPaths: [
        {
            text: `Sales Summary`,
            href: `reports?document=sales_summary`
        },
        {
            text: `Costs Analysis`,
            href: `reports?document=costs_analysis`
        },
        {
            text: `Balance Sheet`,
            href: `reports?document=balance_sheets`
        },
        {
            text: `Profits and Losses`,
            href: `reports?document=profits_and_losses`
        },
        {
            text: `Cash Flows`,
            href: `reports?document=cash_flows`
        },
        {
            text: `Taxes`,
            href: `reports?document=taxes`
        }
    ],
};
