import { createSlice } from "@reduxjs/toolkit";
import { message, Space, Typography } from "antd";
import { billing, checkoutBill, getNotifications, getUser, readNotification, saveProfile, search } from '../_actions/account';
import { copyObject, currentSearch, failureReducer, loadingReducer, objectEqual, setPath, Status } from "../_helpers";

/**
 * @type {AccountState}
 */
const initialState = {
    status: Status.IDLE,
    query: currentSearch(),
    visible: {}, loading: {},
    forms: {},
    options: {},
    checkout: {},
    notifications: [],
    bills: [{ loading: true }],
    payments: [...new Array(3)].map(() => ({ loading: true })),
    errors: {}
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        /**
         * 
         * @returns 
         */
        clear() {
            return { ...initialState }
        },
        /**
         * Set state
         * @param {import('@reduxjs/toolkit').Draft<AccountState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload }
        },
        /**
         * Set notifications
         * @param {import('@reduxjs/toolkit').Draft<AccountState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setNotifications(state, action) {
            var idx;
            const { data } = action.payload;
            for (let x of data) {
                idx = state.notifications?.findIndex(xx => xx.id === x.id)
                if (idx > -1) state.notifications[idx] = x
                else state.notifications.unshift(x)
            }
            state.notifications?.sort((a, b) => b.id.localeCompare(a.id))
        },
        /**
         * Set temporary user data
         * @param {import('@reduxjs/toolkit').Draft<AccountState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setTemp(state, action) {
            var { path, value } = action.payload;
            var data = copyObject(state.info);
            setPath(data, path, copyObject(value));
            if (objectEqual(data, state.info)) state.temp = null
            else state.temp = data;
        },
        /**
         * Delete temporary user data
         * @param {import('@reduxjs/toolkit').Draft<AccountState>} state
         */
        delTemp(state) {
            state.temp = null
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getUser.fulfilled, (state, action) => {
                state.info = action.payload.user;
                state.balance = action.payload.balance;
                state.notifications = action.payload.notifications;
                state.options = action.payload.options;
                state.status = Status.SUCCESS;
            })
            .addCase(search.fulfilled, (state, action) => {
                state.searchList = action.payload
                state.loading = { ...state.loading, search: false }
            })
            .addCase(billing.fulfilled, (state, action) => {
                const { pending_bills, payment_history } = action.payload;
                state.bills = pending_bills;
                state.payments = state.payments.filter(x => !x.loading).concat(
                    payment_history.map(x => ({ ...x, loading: false }))
                );
                state.status = Status.SUCCESS;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.notifications = [...data, ...state.notifications.filter(x => data.find(xx => xx.id === x.id) == null)];
                state.notifications.sort((a, b) => b.id.localeCompare(a.id))
                state.status = Status.SUCCESS;
            })
            .addCase(saveProfile.fulfilled, (state, action) => {
                state.info = action.payload.data;
                state.temp = null;
                state.status = Status.SUCCESS;
            })
            .addCase(readNotification.fulfilled, (state, action) => {
                var idx;
                const { data, options } = action.payload;
                for (let x of data) {
                    idx = state.notifications?.findIndex(xx => xx.id === x.id)
                    if (idx > -1) state.notifications[idx] = x
                    else state.notifications.unshift(x)
                }
                state.notifications.sort((a, b) => b.id.localeCompare(a.id))
                state.options = { ...state.options, ...options }
                state.visible = { ...state.visible, notifications: false }
                state.loading = { ...state.loading, notifications: false }
            })
            .addCase(checkoutBill.fulfilled, (state, action) => {
                if (action.payload.message != null) {
                    message.info(<Space>
                        {action.payload.message}
                        <span><Typography.Link underline href={window.location.href}>Redirect</Typography.Link> in 3 seconds... </span>
                    </Space>);
                    state.status = Status.REDIRECT
                    return
                }
                const { invoice_data, order_list } = action.payload
                state.checkout.summary = invoice_data
                state.checkout.orders = order_list
                state.checkout.expandedKeys = order_list.map(d => d.key)
                state.checkout.visible = true
                state.status = Status.SUCCESS
            })

        builder
            .addMatcher(
                (action) => action.type.startsWith('account') && action.type.endsWith('/rejected'),
                failureReducer
            )
            .addMatcher(
                (action) => action.type.startsWith('account') && action.type.endsWith('/pending'),
                loadingReducer
            )
    }
})

export const { clear, setState, setNotifications, setTemp, delTemp } = accountSlice.actions;

/**
 * Get account slice
 *
 * @param {Object} state
 * @returns {AccountState}
 */
export const selectAccountSlice = (state) => state.account;

export default accountSlice.reducer;

