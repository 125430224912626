import { message } from 'antd';
import streamSaver from "streamsaver";
import 'streamsaver/examples/zip-stream';
import { WritableStream } from 'web-streams-polyfill/ponyfill';
import { Admin, Agent, Client, CS, Editor, PM, QC } from "../_api";
import { apiRole } from "./constants";
import { copyObject } from './objects';

/**
 * States of the slice
 * @readonly
 * @enum {string}
 */
export const Status = {
    /** The initial state */
    IDLE: 'idle',
    /** The loading state */
    LOADING: 'loading',
    /** The success state */
    SUCCESS: 'success',
    /** The error state */
    FAILURE: 'failure',
    /** The redirect state */
    REDIRECT: 'redirect',
};
/**
 * Check if error is an ApiError
 *
 * @param {Object} error
 * @returns {boolean} error is ApiError
 */
export function isApiError(error) {
    return typeof error === 'object' && error !== null && 'errors' in error;
}
/**
 * Turn error into string display
 *
 * @param {Object} error
 * @returns {Object} { message: String }
 */
export function serializeError(error) {
    if (error instanceof Error) {
        return {
            name: error.name,
            message: error.message,
            stack: error.stack,
            code: error.code,
        };
    }

    if (typeof error === 'object' && error !== null) {
        return error;
    }
    return { message: String(error) };
}
/**
 * Set state as loading
 *
 * @param {import('@reduxjs/toolkit').Draft<Object>} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{errors: Record<string, string[]}>} action
 */
export function loadingReducer(state, action) {
    if (action.type.includes('[loading]')) {
        const part = action.type.split('/').find(x => x.includes('[loading]'))?.split('[')[0]
        state.loading[part] = true
    } else state.status = Status.LOADING;
}
/**
 * @param {import('@reduxjs/toolkit').Draft<Object>} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{errors: Record<string, string[]}>} action
 */
export function failureReducer(state, action) {
    if (action.type.includes('[loading]')) {
        const part = action.type.split('/').find(x => x.includes('[loading]'))?.split('[')[0]
        state.loading[part] = false
    }
    state.status = Status.FAILURE;
    state.errors = action.payload?.errors || action.error || [];
    message.error(Object.values(state.errors).join('\n'), 10)
}
/**
 * 
 * @param {String} url 
 * @param {String} title 
 */
export function download(url, title) {

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        title,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

}
/**
 * 
 * @param {Array<Object>} data 
 */
export function streamDownload(zipName = null, zipSize = null, data) {
    // If the WritableStream is not available (Firefox, Safari), take it from the ponyfill
    if (!window.WritableStream) {
        streamSaver.WritableStream = WritableStream;
        window.WritableStream = WritableStream;
    }

    const fileStream = streamSaver.createWriteStream(`${zipName || 'compress'}.zip`, { size: parseInt(zipSize) })
    
    const files = data.map(x => [x.name, x.url]).values()
    // Getting a iterator `.values()` is good for task where you
    // have to iterate over values but can't use a for-loop, due to async nature
    data.forEach(x => console.log(x.name))
    console.log(window.WritableStream)
    
    new window.ZIP({
        pull(ctrl) {
            const it = files.next()
            if (it.done) {
                ctrl.close()
            } else {
                const [name, url] = it.value

                return fetch(url).then(res => {
                    ctrl.enqueue({
                        name,
                        stream: () => res.body
                    })
                })
            }
        }
    }).pipeTo(fileStream)
}
/**
 * 
 * @param {String} zipName 
 * @param {Array<Object>} files 
 */
export async function multiDownload(zipName, files) {
    const sizeLimit = 4294967296
    var data = []
    var tempSize = 0
    var tempData = []
    for (let x of files) {
        tempSize += x.size
        if (tempSize < sizeLimit) {
            tempData.push(x)
        } else {
            data.push(copyObject(tempData))
            tempSize = 0; tempData = [x]
        }
    }
    if (tempData.length > 0) data.push(tempData)
    // await Api().download(record.id, selected.map(x => x.path))
    for (let x of data) {
        const zipSize = x.map(x => x.size).reduce((a, b) => (a + b), 0)
        if (zipSize < 4294967296) {
            streamDownload(
                zipName, zipSize,
                x.map(x => ({ name: x.path, url: x.url }))
            );
        }
    }
}
/**
 * 
 * @param {Function} conditionFunction 
 * @returns {Promise<Function>}
 */
export function until(conditionFunction) {

    const poll = resolve => {
        if (conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 400);
    }

    return new Promise(poll);
}
/**
 * 
 * @param {String} url 
 * @returns {Boolean}
 */
export function urlExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status !== 404)
        return true;
    else
        return false;
}
/**
 * 
 * @returns {Object}
 */
export function Api() {
    switch (apiRole()) {
        case "admin": return Admin
        case "pm": return PM
        case "cs": return CS
        case "qc": return QC
        case "editor": return Editor
        case "client": return Client
        case "agent": return Agent
        default: return
    }
}

