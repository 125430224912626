
import { currencyFormat } from '../../../_helpers';
import ArrayInfo from '../../display/ArrayInfo';
import TagInfo from '../../display/TagInfo';

export const contractColumns = [
    {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        width: '15%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
    },
    {
        key: 'product',
        title: 'Product',
        dataIndex: 'product',
        width: '15%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.product || '').localeCompare(b.product || ''),
    },
    {
        key: 'price',
        title: 'Price',
        dataIndex: 'price',
        width: '5%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.price?.amount > b.price?.amount,
        render: (item, record, index) => currencyFormat(item?.amount, item?.currency)
    },
    {
        key: 'edit_rate',
        title: 'Edit Rate',
        dataIndex: 'edit_rate',
        width: '5%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.edit_rate?.amount > b.edit_rate?.amount,
        render: (item, record, index) => currencyFormat(item?.amount, item?.currency)
    },
    {
        key: 'qc_rate',
        title: 'QC Rate',
        dataIndex: 'qc_rate',
        width: '5%',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.qc_rate?.amount > b.qc_rate?.amount,
        render: (item, record, index) => currencyFormat(item?.amount, item?.currency)
    },
    {
        key: 'clients',
        title: 'Clients',
        dataIndex: 'clients',
        width: '15%',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'editors',
        title: 'Editors',
        dataIndex: 'editors',
        width: '15%',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'qcs',
        title: 'QCs',
        dataIndex: 'qcs',
        width: '15%',
        ellipsis: { showTitle: false },
        render: (items) => <ArrayInfo {...{ items }} />
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        ellipsis: { showTitle: false },
        sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
        render: (items) => <TagInfo {...{ items }} />
    },
]