import { isNullObject, objectEqual } from './objects'

/**
 * 
 * @param {Object} a 
 * @param {Object} b 
 * @param {Object} schema 
 * @returns 
 */
export function recordEqual(a, b, schema = []) {
    for (let field of schema) {
        if (field.parentKey == null) {

            if (!objectEqual(a[field.key], b[field.key])) return false
        } else {
            if (a[field.parentKey] != null && b[field.parentKey] != null) {
                if (
                    !objectEqual(a[field.parentKey][field.key], b[field.parentKey][field.key])
                ) return false
            } else if (a[field.parentKey] !== b[field.parentKey]) return false
        }
    }
    return true
}
/**
 * 
 * @param {Array<Object>} schema 
 * @param {Object} config 
 * @returns 
 */
export function validateSchema(schema, config) {
    var filteredSchema = config ?
        config.map(
            x => {
                var field = schema.find(xx => xx.title === x.title)
                var editable = x?.editable != null ? (r) => x?.editable : field.editable
                return field == null ? null : { ...x, ...field, editable }
            }
        ).filter(x => x !== null)
        : schema

    return filteredSchema.map(x => ({
        ...x,
        editable: x.editable || ((r) => true)
    }))
}
/**
 * 
 * @param {Array<Object>} columns 
 * @param {Object} config 
 * @returns 
 */
export function validateColumns(columns, config) {
    return config ? columns.filter(x => config.includes(x.key)) : columns
}
/**
 * 
 * @param {Object} record order record data
 * @param {*} options 
 * @returns 
 */
export function validateOrderUpdate(record, options) {
    return {
        ...record,
        product_list: record.product_list.map(item => {
            var prtData = {
                ...item,
                product: options?.product?.find(opt => opt.value === item.product_title)?.id,
                editor: options?.editor?.find(opt => opt.value === item.editor_name)?.id,
                qc: options?.qc?.find(opt => opt.value === item.qc_name)?.id,
                price: isNullObject({ curreny: item.price?.currency }) ? null : { ...item.price },
                edit_rate: isNullObject({ curreny: item.edit_rate?.currency }) ? null : { ...item.edit_rate },
                qc_rate: isNullObject({ curreny: item.qc_rate?.currency }) ? null : { ...item.qc_rate },
            }
            delete prtData.id
            return typeof item.id === 'number' ? prtData : { ...prtData, id: item.id }
        }),
        post_requests: record.post_requests.map(item => {
            var reqData = {
                ...item,
                editor: options?.editor?.find(opt => opt.value === item.editor_name)?.id,
                qc: options?.qc?.find(opt => opt.value === item.qc_name)?.id,
            }
            delete reqData.id
            return typeof item.id === 'number' ? reqData : { ...reqData, id: item.id }
        }),
    }
}
/**
 * 
 * @param {Array<Object>} data 
 * @param {String} id 
 * @param {String} key 
 * @param {String} value 
 * @returns {Boolean} mutate data and return isMutated
 */
export function setLeafValue(data, id, key, value) {
    var isMutated = false
    for (let x of data) {
        if (x.isLeaf === true) {
            if (x.key === id) { x[key] = value; return true }
            return false
        } else isMutated = setLeafValue(x.children, id, key, value)
        if (isMutated === true) return true
    }

    return false
}
/**
 * 
 * @param {Array<Object>} data 
 * @returns 
 */
export const parseTaxSalaries = (data) => {
    return data.salaries?.map(
        (x, i) => {
            const total_audit = x.audit_payments?.map(p => p.converted_amount).reduce((a, b) => a + b, 0) || 0
            const total_inaudit = x.inaudit_payments?.map(p => p.converted_amount).reduce((a, b) => a + b, 0) || 0
            const total_extras = x.extras?.map(ex => ex.converted_amount).reduce((a, b) => a + b, 0) || 0
            const total_insurances = x.insurances?.map(ins => ins.converted_amount).reduce((a, b) => a + b, 0) || 0
            const total_payment = total_audit + total_inaudit
            return {
                ...x,
                base_currency: data.base_currency,
                key: i,
                total_audit,
                total_inaudit,
                total_extras,
                total_insurances,
                total_payment
            }
        }).sort((a, b) => b.total_payment - a.total_payment) || []
}
/**
 * 
 * @param {Array<Object>} data 
 * @returns 
 */
export const parseTaxReport = (data) => {
    var total_audit_revenues = [].concat(...data.audit_revenues?.map(r => r.payments.map(p => p.converted_amount)) || []).reduce((a, b) => a + b, 0)
    var total_inaudit_revenues = [].concat(...data.inaudit_revenues?.map(r => r.payments.map(p => p.converted_amount)) || []).reduce((a, b) => a + b, 0)
    var total_revenues = total_audit_revenues + total_inaudit_revenues
    var audit_salaries = [].concat(...data.salaries?.map(x => x.audit_payments.map(p => p.converted_amount)) || []).reduce((a, b) => a + b, 0)
    var inaudit_salaries = [].concat(...data.salaries?.map(x => x.inaudit_payments.map(p => p.converted_amount)) || []).reduce((a, b) => a + b, 0)
    var total_fixed_costs = data.fixed_costs?.map(x => x.converted_amount).reduce((a, b) => a + b, 0) || 0
    var total_insurances = [].concat(...data.salaries?.map(x => x.insurances.map(ins => ins.converted_amount)) || []).reduce((a, b) => a + b, 0)
    var insurances_salary_minus = -1.0 * [].concat(...data.salaries?.map(
        x => x.extras.filter(ex => ex.description === 'social insurance').map(ex => ex.converted_amount)
    ) || []).reduce((a, b) => a + b, 0)
    var ebt = total_audit_revenues - audit_salaries - total_fixed_costs - total_insurances + insurances_salary_minus
    var business_tax = (data.business_tax_rate || 0) * ebt
    var freelance_tax = (data.freelancer_tax_rate || 0) * [].concat(
        ...data.salaries?.filter(x => x.insurances.length === 0)
            .map(x => x.audit_payments.map(p => p.converted_amount)) || [])
        .reduce((a, b) => a + b, 0)
    var insurance_tax = -1.0 * [].concat(...data.salaries?.map(
        x => x.extras.filter(ex => ex.description === 'personal taxes').map(ex => ex.converted_amount)
    ) || []).reduce((a, b) => a + b, 0)

    return {
        ...data,
        total_revenues,
        total_audit_revenues,
        total_audit_revenues_pct: Math.round(total_audit_revenues / total_revenues * 100),
        total_inaudit_revenues,
        total_inaudit_revenues_pct: Math.round(total_inaudit_revenues / total_revenues * 100),
        audit_salaries,
        audit_salaries_pct: Math.round(audit_salaries / total_revenues * 100),
        inaudit_salaries,
        inaudit_salaries_pct: Math.round(inaudit_salaries / total_revenues * 100),
        total_salaries: audit_salaries + inaudit_salaries,
        total_salaries_pct: Math.round(audit_salaries + inaudit_salaries / total_revenues * 100),
        total_fixed_costs,
        total_fixed_costs_pct: Math.round(total_fixed_costs / total_revenues * 100),
        total_insurances,
        insurances_salary_minus,
        insurances_company_fund: total_insurances - insurances_salary_minus,
        ebt,
        ebt_pct: Math.round(ebt / total_revenues * 100),
        business_tax,
        freelance_tax,
        insurance_tax,
        total_taxes: business_tax + freelance_tax + insurance_tax
    }
}
/**
 * @readonly
 * @constant {Object}
 */
export const ParamType = {
    'String': { type: 'Input' },
    'Integer': { type: 'Input', inputType: 'number' },
    'Float': { type: 'Input', inputType: 'number' },
    'Select': { type: 'Select' },
    'Integer Array': { type: 'TagSelect' },
    'Float Array': { type: 'TagSelect' },
    'String Array': { type: 'TagSelect' },
    'Boolean': { type: 'Switch', checkedText: 'Yes', uncheckedText: 'No' }

}