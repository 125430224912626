import { numberFormat } from './formats'
import dayjs from 'dayjs';

/**
 * Serialize object to URL params
 *
 * @param {Record<string, unknown>} object
 * @returns {String}
 */
export function serializeQuery(object) {
    const params = [];

    for (const param in object) {
        if (Object.hasOwnProperty.call(object, param) && object[param] != null) {
            params.push(`${param}=${encodeURIComponent(object[param])}`);
        }
    }

    return params.join('&');
}
/**
 * Parse URL params to object
 *
 * @param {String} search
 * @returns {Record<string, unknown>}
 */
export function parseQuery(search) {
    var query = search.replace(/^\?/g, "")
    const object = {}
    for (let param of query.split("&")) {
        if (!param.includes("=")) continue
        const [key, value] = param.split("=")
        object[key] = value
    }
    return object
}
/**
 * 
 * @param {Object} record 
 * @returns 
 */
export function parseRecord(record, key = '') {
    return { ...record, key: record.id || key }
}
/**
 * 
 * @param {String} type 
 * @param {*} value 
 * @returns 
 */
export function parseInputType(type, value) {
    switch (type) {
        case 'number': return [null, undefined, ''].includes(value) ? null : Number(value)
        case 'text': return String(value)
        case 'string': return String(value)
        case "Boolean": return value === true ? "Yes" : "No"
        case "Integer": return numberFormat(value, '0,0')
        case "Float": return numberFormat(value)
        case "Integer Array": return value.join(' / ')
        case "Float Array": return value.join(' / ')
        case "String Array": return value.join(' / ')
        default: return value
    }
}
/**
 * 
 * @param {Array<Object>} items 
 * @returns {Promise<Array<Object>>}
 */
export function parseFilesUpload(items) {
    function traverse(item, itemList = [], treeData) {
        return new Promise(resolve => {
            if (item.isFile) {
                item.file(file => {
                    file.fullPath = item.fullPath; //save full path
                    const pathList = item.fullPath.split('/');
                    if (pathList.length > 2) {
                        file.path = pathList.slice(1, pathList.length - 1).join('/')
                    }
                    itemList.push(file);
                    treeData.push({ title: file.name, key: item.fullPath, children: [] });
                    resolve(file);
                });
            } else if (item.isDirectory) {
                let dirReader = item.createReader();
                dirReader.readEntries(entries => {
                    let entriesPromises = [];
                    const children = [];
                    treeData.push({ title: item.name, key: item.fullPath, children });
                    for (let entr of entries) {
                        entriesPromises.push(
                            traverse(entr, itemList, children)
                        );
                    }
                    resolve(Promise.all(entriesPromises));
                });
            }
        });
    }

    let itemList = []
    let treeData = [];
    return new Promise((resolve, reject) => {
        let entriesPromises = [];
        for (let it of items)
            entriesPromises.push(
                traverse(it.webkitGetAsEntry(), itemList, treeData)
            );
        Promise.all(entriesPromises).then(entries => {
            resolve({ itemList, treeData });
        });
    });
}
/**
 * 
 * @param {Number} timeInt 
 * @param {String} format 
 * @returns {String}
 */
export function timestampToDate(timeInt, format = 'HH:mm:ss MMM D, YYYY') {
    return dayjs.unix(timeInt).format(format)
}
/**
 * 
 * @param {Array<dayjs.Dayjs, dayjs.Dayjs>} dates 
 * @returns {Object}
 */
export function dateToWeekRange(dates) {
    var startDate = dates[0]
    var endDate = dates[1]
    return {
        from_date: startDate.subtract(startDate.day() - 1, 'days').format('YYYY-MM-DD'),
        to_date: endDate.add(7 - endDate.day(), 'days').format('YYYY-MM-DD')
    }
}
/**
 * 
 * @param {Number} start 
 * @param {Number} end 
 * @returns {Array<Number>}
 */
export function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};