export const statusTags = [
    {
        color: 'magenta',
        values: []
    },
    {
        color: 'red',
        values: ['outflow']
    },
    {
        color: 'volcano',
        values: [
            'locked', 'loss', 'pending', 'failure',
            'put', 'delete', 'invalid'
        ]
    },
    {
        color: 'orange',
        values: ['pending', 'test_working']
    },
    {
        color: 'gold',
        values: [
            'payable', 'ed_payable', 'qc_payable', 'sales_payable',
            'edited', 'working', 'salary', 'processing',
            'get', 'cs', 'ordered'
        ]
    },
    {
        color: 'lime',
        values: ['unread', 'test_sent']
    },
    {
        color: 'green',
        values: ['active', 'new', 'post', 'success', 'invoice', ]
    },
    {
        color: 'cyan',
        values: [
            'done', 'paid', 'delivered', 'ed:delivered', 'rq:delivered',
            'inflow', 'true', 'editor', 'set'
        ]
    },
    {
        color: 'blue',
        values: [
            'billed', 'ed_billed', 'ed_paid', 'qc_billed', 'qc_paid', 'sales_billed', 'sales_paid',
            'sent', 'qc', 'completed', 'read', 'price_sent'
        ]
    },
    {
        color: 'geekblue',
        values: [
            'inactive', 'canceled', 'partially billed', 'wage',
            'false', 'patch', 'unset'
        ]
    },
    {
        color: 'purple',
        values: ['deleted', 'bad_debt', 'declined']
    },
]

export const periodTags = [
    { period: "Morning", status: "ATTENDED", color: "orange", label: "sang" },
    { period: "Afternoon", status: "ATTENDED", color: "red", label: "chieu" },
    { period: "Evening", status: "ATTENDED", color: "blue", label: "toi" },
    { period: "Morning", status: "OFF", color: "default", label: "sa-off" },
    { period: "Afternoon", status: "OFF", color: "default", label: "ch-off" },
    { period: "Evening", status: "OFF", color: "default", label: "to-off" },
]