
import dayjs from 'dayjs';

export const logColumns = [
    {
        key: 'timestamp',
        title: 'Time',
        dataIndex: 'timestamp',
        width: '25%',
        sorter: (a, b) => (a.timestamp || '').localeCompare(b.timestamp || ''),
        render: item => dayjs.unix(item).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        key: 'call_type',
        title: 'Call Type',
        dataIndex: 'call_type',
        // width: '25%',
        sorter: (a, b) => (a.product || '').localeCompare(b.call_type || ''),
    },
    {
        key: 'method',
        title: 'Method',
        dataIndex: 'method',
        sorter: (a, b) => (a.method || '').localeCompare(b.method || ''),
    },
    {
        key: 'ip_address',
        title: 'IP',
        dataIndex: 'ip_address',
        sorter: (a, b) => (a.ip_address || '').localeCompare(b.ip_address || ''),
    },
    {
        key: 'user_email',
        title: 'User',
        dataIndex: 'user_email',
        sorter: (a, b) => (a.user_email || '').localeCompare(b.user_email || ''),
    },
]