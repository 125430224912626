import { parseQuery } from "./parsers"

/**
 * User jwt token
 * @readonly
 * @constant {String}
 */
export const localToken = () => {
    const base64regex = /^[A-Za-z0-9_-]{2,}(?:\.[A-Za-z0-9_-]{2,}){2}$/;
    const jwt = localStorage.getItem('jwt');
    if (base64regex.test(jwt) === true) return jwt;
    return null
}
/**
 * Regex expression for url path
 * @readonly
 * @constant {Regex}
 */
export const pathregex = /^\/|\/$/g
/**
 *
 * @returns {Array<String>}
 */
export const currentPaths = () => {
    return window.location?.pathname.split('/')
}
/**
 * 
 * @returns {Objects}
 */
export const currentSearch = () => parseQuery(window.location?.search)
/**
 * 
 * @returns {String|null}
 */
export const apiRole = () => currentPaths().length > 1 ? currentPaths()[1] : null
/**
 * 
 * @returns {String|null}
 */
export const apiType = () => currentPaths().length > 2 ? currentPaths()[2] : null

export const isMobile = () => window.innerWidth < 700
