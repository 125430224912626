import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "../_api";
import { isApiError, serializeError, Status } from "../_helpers";

/**
 * Send a register request
 *
 * @param {Object} data
 */
export const register = createAsyncThunk(
    'auth/register',
    async (data, thunkApi) => {
        try {
            return await Auth.register(data)
        } catch (error) {
            if (isApiError(error)) {
                return thunkApi.rejectWithValue(error);
            }

            throw error;
        }
    },
    {
        condition: (_, { getState }) => !(getState().auth.status === Status.LOADING),
        serializeError
    }
);
/**
 * Send a login request
 *
 * @param {String} email
 * @param {String} password
 */
export const login = createAsyncThunk(
    'auth/login',
    async ({ username, password }, thunkApi) => {
        try {
            return await Auth.login(username, password)
        } catch (error) {
            if (isApiError(error)) {
                return thunkApi.rejectWithValue(error);
            }
            throw error;
        }
    },
    {
        condition: (_, { getState }) => !(getState().auth.status === Status.LOADING),
        serializeError
    }
);
/**
 * Send a role initialization request
 *
 * @param {str}  role
 * @returns {Promise<UserAuth>}
 */
export const initializeRole = createAsyncThunk(
    'auth/login',
    async ({ role, location }, _) => await Auth.initializeRole(role, location),
    { serializeError }
);
/**
 * Send a get reset token request
 *
 * @param {String} key
 * @param {String} type
 * @param {Object} data
 */
export const getResetToken = createAsyncThunk(
    'auth/getResetToken',
    async ({ key, type, data }, _) => await Auth.getResetToken(key, type, data),
    {
        condition: (_, { getState }) => !(getState().auth.status === Status.LOADING),
        serializeError
    }
);

