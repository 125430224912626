import { Col, Row, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Reducer } from '../../../_helpers';
import { getTimebookColumns } from './columns';
import BaseTable from '../_base/BaseTable';
import { periodTags } from '../../../_styles/tag.styles';

export function TimebookTable(props) {
  const { selectSlice } = Reducer()
  const { options, columns, records, temp } = useSelector(selectSlice)
  // Filter and Add User columns to table
  const userSelect = columns?.selected?.users || [...new Set(options?.columns?.map(x => x.user))]
  const tagSelect = columns?.selected?.tags || [...new Set([].concat(...(options?.columns?.map(x => x.tags) || [])))]
  const columnSelect = options?.columns?.filter(
    col => tagSelect.findIndex(tag => col.tags.includes(tag)) > -1 && userSelect.includes(col.user)
  ) || []
  const data = records.map(x => temp.find(xx => xx.id === x.id) || x)
  const tableProps = {
    columns: getTimebookColumns(columnSelect.map(col => ({
        title: col.user,
        dataIndex: col.user,
        width: '90px',
        render: (items, record, index) => {
          return <Row key={record.key}>{
            items?.filter(it => it.status !== "UNASSIGNED").map(it => {
              var display = periodTags.find(x => x.period === it.period && x.status === it.status)
              return <Col key={it.period}><Tag color={display.color}>{display.label}</Tag></Col>
            })
          }</Row>
        }
      }))
    ),
    searchKeys: ['date', 'week_day', 'status'],
    arrayKeys: [],
    rowSelection: true,
    bordered: true
  }

  return (
    <BaseTable
      {...props}
      tableProps={tableProps}
      data={data}
    />
  )
}
