import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { config } from '../../app/config'
import { selectAuthSlice } from '../../_reducers/auth'

function Home() {
    const { user } = useSelector(selectAuthSlice)
    var pathname = config.rolePaths.find(x => user?.roles.includes(x.role))?.loginPath
    return (
        pathname != null && <Navigate to={{ pathname, state: { from: window.location } }} />
    )
}

export default Home