import { createSlice } from "@reduxjs/toolkit";
import { pnlColumns, taxColumns } from "../components/tables";
import { create } from "../_actions/dashboard";
import { getBillsForTax, getStatistics, recalculateTax, reports } from "../_actions/reports";
import { copyObject, currentSearch, failureReducer, loadingReducer, objectEqual, parseRecord, setPath, Status } from "../_helpers";

/**
 * Set table columns
 * @param {*} doc 
 * @returns 
 */
export const getColumns = (doc) => {
    var options = []
    switch (doc) {
        case 'taxes': options = taxColumns; break;
        case 'profits_and_losses': options = pnlColumns; break;
        default: break;
    }
    options = options.length > 0 ? [
        { label: '(Selection)', value: 'selection' },
        ...options.map(x => ({ label: x.title, value: x.key }))
    ] : []
    var selected
    const localCols = JSON.parse(localStorage.getItem(`columns/${doc}`))
    selected = localCols?.length > 0 ? localCols : options.map(x => x.value)
    localStorage.setItem(`columns/${doc}`, JSON.stringify(selected))
    return {
        selected: selected,
        options
    }
}
/**
 * @type {ReportState}
 */
const initialState = {
    query: currentSearch(),
    columns: getColumns(currentSearch().document),
    status: Status.IDLE,
    visible: {},
    loading: {},
    table: {},
    extras: {},
    forms: {},
    records: [],
    temp: [],
    statistics: {}
};

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        /**
         * 
         * @returns 
         */
        clear() {
            return { ...initialState, query: {}, columns: {} }
        },
        /**
         * Set state
         * @param {import('@reduxjs/toolkit').Draft<ReportState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setState(state, action) {
            return { ...state, ...action.payload }
        },
        /**
          * Select a table row
          * @param {import('@reduxjs/toolkit').Draft<ReportState>} state
          * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
          */
        selectRow(state, action) {
            const { key } = action.payload
            if (key != null && state.records.find(x => x.key === key) == null) return
            state.selectedKey = key;
            state.visible.drawer = key == null ? false : true;
            state.extras = { record: { ...state.records.find(x => x.key === key) } }
        },
        /**
         * Temporarily set the record
         * @param {import('@reduxjs/toolkit').Draft<ReportState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        setTemp(state, action) {
            var recIdx, tempIdx, rec;
            var { records, temp } = state;
            var { key, path, value } = action.payload;
            recIdx = records.findIndex(x => x.key === key);
            tempIdx = temp.findIndex(x => x.key === key);
            rec = copyObject(tempIdx > -1 ? temp[tempIdx] : (recIdx > -1 ? records[recIdx] : null));
            setPath(rec, path, copyObject(value));
            if (objectEqual(rec, records[recIdx])) {
                if (tempIdx > -1) temp.splice(tempIdx, 1);
            } else {
                if (tempIdx > -1) temp[tempIdx] = rec
                else temp.push(rec);
            };
        },
        /**
         * Remove record from temporary list
         * @param {import('@reduxjs/toolkit').Draft<ReportState>} state
         * @param {import('@reduxjs/toolkit').PayloadAction<Object>} action
         */
        delTemp(state, action) {
            const tempIdx = state.temp.findIndex(x => x.key === action.payload.key)
            if (tempIdx > -1) state.temp.splice(tempIdx, 1);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(reports.fulfilled, (state, action) => {
                var { data, statistics, options, extras } = action.payload;
                if (statistics == null && data != null) {
                    state.records = [
                        ...state.records,
                        ...data.map((x, i) => parseRecord(x, i))
                            .filter(d => state.records.findIndex(sd => sd.id === d.id) < 0),
                    ];
                } else state.statistics = statistics;
                state.options = options || {};
                state.extras = { ...state.extras, ...extras };
                state.loading = {};
                state.status = Status.SUCCESS;
            })
            .addCase(create.fulfilled, (state, action) => {
                var { data } = action.payload;
                if (!Array.isArray(data)) data = [data];
                state.records = [...data.map((x, i) => parseRecord(x, i)), ...state.records];
                state.status = Status.SUCCESS;
            })
            .addCase(recalculateTax.fulfilled, (state, action) => {
                var { data } = action.payload;
                if (!Array.isArray(data)) data = [data];
                for (let d of data) {
                    // reset record in data array
                    const recIdx = state.records.findIndex(x => x.id === d.id);
                    if (recIdx > -1) state.records[recIdx] = parseRecord(d, recIdx);
                    // remove record in temp
                    const tempIdx = state.temp.findIndex(x => x.id === d.id);
                    if (tempIdx > -1) state.temp.splice(tempIdx, 1);
                }
                state.status = Status.SUCCESS;
            })
            .addCase(getStatistics.fulfilled, (state, action) => {
                const { name, data } = action.payload;
                state.statistics[name] = data;
                state.status = Status.SUCCESS;
            })
            .addCase(getBillsForTax.fulfilled, (state, action) => {
                state.options['billsForTax'] = action.payload.data;
                state.status = Status.SUCCESS;
            })

        builder
            .addMatcher(
                (action) => action.type.startsWith('reports') && action.type.endsWith('/rejected'),
                failureReducer
            )
            .addMatcher(
                (action) => action.type.startsWith('reports') && action.type.endsWith('/pending'),
                loadingReducer
            )
    }
})

export const {
    clear, setState, addOption, selectRow, setTemp, delTemp,
} = reportSlice.actions;

/**
 * Get report slice
 *
 * @param {Object} state
 * @returns {ReportState}
 */
export const selectReportSlice = (state) => state.reports;

export default reportSlice.reducer;

