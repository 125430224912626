import TagInfo from '../../display/TagInfo';

export const roleParamColumns = [
   {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
   },
   {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      width: '15%',
      sorter: (a, b) => (a.role || '').localeCompare(b.role || ''),
   },
   {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      width: '30%',
      sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
   },
   {
      key: 'option_key',
      title: 'Option Key',
      dataIndex: 'option_key',
      width: '20%',
      sorter: (a, b) => (a.option_key || '').localeCompare(b.option_key || ''),
   },
   {
      key: 'user_updatable',
      title: 'Updatable',
      dataIndex: 'user_updatable',
      width: '15%',
      render: (items) => <TagInfo {...{ items }} />
   }
]