import React from 'react';
import { useSelector } from 'react-redux';
import { parseTaxReport, Reducer } from '../../../_helpers';
import TaxPaySalaryForm from '../../forms/taxPaySalaries/TaxPaySalaryForm';
import TaxRecalculateForm from '../../forms/taxRecalculate/TaxRecalculateForm';
import BaseTable from '../_base/BaseTable';
import RecordActions from '../_shared/RecordActions';
import { taxColumns } from './columns';

export function TaxTable(props) {
    const { selectSlice } = Reducer();
    const { records } = useSelector(selectSlice);
    const { display } = props
    const tableProps = {
        columns: [
            ...taxColumns,
            {
                key: 'action',
                title: '',
                dataIndex: null,
                width: '2vw',
                render: (text, record, index) => <RecordActions
                    title={record.period}
                    display={{
                        actions: display?.actions || [
                            'Pay salaries', 'Recalculate tax'
                        ]
                    }}
                    record={record}
                />
            }
        ],
        searchKeys: ['period'],
        arrayKeys: [],
    }

    return (
        <>
            <TaxPaySalaryForm {...props} />
            <TaxRecalculateForm {...props} />
            <BaseTable
                {...props}
                tableProps={tableProps}
                data={records.map(x => parseTaxReport(x))}
            />
        </>

    )
}
